import { LoaderGenerating, PageHeaderLogs } from 'devlink'
import { characterQueries } from 'entities/character'
import { characterStore } from 'entities/character/character.model'
import { sessionQueries } from 'entities/session'
import { LogsContext } from 'pages/logsanalysis/logsanalysis-page.model'
import { useContext, useLayoutEffect, useState } from 'react'
import { sessionStore } from '../../entities/session/session.model'

type TeamOption = {
    t: string
    v: string
}

export function PageHeaderLogsContainer() {
    const [teamOptions, setTeamOptions] = useState<TeamOption[]>([])
    const [currentTeam, setCurrentTeam] = useState<
        TeamOption | null | undefined
    >()
    const [showCopyPopup, setShowCopyPopup] = useState(false)
    const [isDropdownProcessing, setIsDropdownProcessing] = useState(false)

    const {
        setIsModalBestPracticeOpen,
        setIsModalTeaserOpen,
    } = useContext(LogsContext)

  

    useLayoutEffect(() => {
        const currentState = characterStore.getState()
        if (currentTeam && currentState.currentTeam?.name !== currentTeam.t) {
            getKeys()
            getNpcs()
            getImages()
            getAudios()
            return
        }
        if (!currentState.npcs){
            getNpcs()
        }
        if (!currentState.images){
            getImages()
        }
        if (!currentState.audios){
            getAudios()
        }
    }, [currentTeam])
    
    const {
        mutate: getKeys,
    } = sessionQueries.useGetKeysMutation(false)

    const { 
        mutate: getNpcs,
    } = characterQueries.useNpcsService()

    
    const { 
        mutate: getBackground,
    } = characterQueries.useBackgroundService()

    const { 
        mutate: getImages,
    } = characterQueries.useImageListService()

    const {
        mutate: getAudios,
    } = characterQueries.useAudioListService()

    useLayoutEffect(() => {
        const currentState = sessionStore.getState()
        setTeamOptions(
            currentState.teams?.map((team, index) => ({
                t: team.name,
                v: index.toString(),
            })) || []
        )
    }, [])

    useLayoutEffect(() => {
        if (!teamOptions || teamOptions.length === 0) return
        const currentState = characterStore.getState()
        setCurrentTeam(
            teamOptions.find((team) => team.t === currentState.currentTeam?.name) ||
                null
        )
    }, [teamOptions])

    const downloadFile = (path: string, filename: string) => {
        const link = document.createElement('a');
        link.href = path;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <PageHeaderLogs
            rpBestButton={{
                className: `button is-secondary`,
                onClick: (e: Event) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(true)
                },
            }}
            rpButtonAPIDocumentation={{
                className: `button is-icon`,
                onClick: (e: Event) => {
                    e.preventDefault()
                    window.open('https://documenter.getpostman.com/view/15655549/2sAYdfrBTZ', '_blank')
                },
            }}
            rpButtonTeaserLogs={{
                className: `button is-secondary`,
                onClick: (e: Event) => {
                    e.preventDefault()
                    setIsModalTeaserOpen?.(true)
                },
            }}
            rpDownloadTemplateLogs={{
                    onClick: (e: Event) => {
                        e.preventDefault();
                        const timestamp = new Date().getTime();
                        downloadFile(`./logs/logs_template.csv`, `logs_template_${timestamp}.csv`);
                      },
                    }}
            rpTeamsDropdown={{
                
                value: currentTeam?.v || '',
                name: currentTeam?.t || '',
                options: teamOptions,
                onChange: (event: any) => {
                    const team = teamOptions.find(
                        (team) => team.v === event.target.value
                    )
                    getKeys()
                    getNpcs()
                    getImages()
                    setCurrentTeam(team)
                    setIsDropdownProcessing(true) 
                    setTimeout(() => setIsDropdownProcessing(false), 2000) 
                    characterStore.getState().updateCurrentTeam(
                        sessionStore.getState().teams?.find(
                            (t) => t.name === team?.t
                        ) || ''
                    )

                },
            }}
            rpCopyKeys={{
                onClick: (e: Event) => {
                    e.preventDefault()
                    const currentState = sessionStore.getState()
                    const keys = currentState.keys

                    if (!keys) return
                    type KeyObject = {
                        [key: string]: string
                    }

                    const transformedKeys: KeyObject = Object.entries(
                        keys
                    ).reduce((acc: KeyObject, [key, value]) => {
                        acc[key] = value.key
                        return acc
                    }, {})

                    navigator.clipboard.writeText(
                        JSON.stringify(transformedKeys, null, 2)
                    )

                    setShowCopyPopup(true)
                    setTimeout(() => setShowCopyPopup(false), 1000) 
                },
            }}
            slotGenerationProcessing={
                isDropdownProcessing && <LoaderGenerating />
            }
            showCopyPopup={showCopyPopup}
            showDropdown={teamOptions.length > 1} 

        />
    )
}

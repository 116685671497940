import { PopupNotification, SectionClosed, SectionDataset } from 'devlink'
import { characterStore, Team } from 'entities/character/character.model'
import { Npc } from 'entities/character/character.types'
import {
    useDeleteAudio,
    useDeleteNpc,
    useUploadAudio,
    useUploadImage,
    useUploadNpc,
} from 'entities/db/db.queries'
import { DatasetContext } from 'pages/dataset'
import React, { useContext, useLayoutEffect, useState } from 'react'

type Options = {
    v: string
    t: string
}

type DatasetGenerationContainerProps = {
    characters: Npc[]
    selectedTeam: Team
    audios: any[]
    images: any[]
}

type VoiceFile = {
    file: File | null
    audioUrl: string | null
    emotion: string
    fileInfo: string
    updated: boolean
    deleted: boolean
    playing: boolean
    preset: boolean
}

export function MyCharacters({
    characters,
    selectedTeam,
    images,
    audios,
}: DatasetGenerationContainerProps) {
    const [voiceFiles, setVoiceFiles] = useState<VoiceFile[]>([])
    const [playerName, setPlayerName] = useState('')
    const [background, setBackground] = useState('')
    const [cacheTimestamp, setCacheTimestamp] = useState<string>(
        new Date().getTime().toString()
    )
    const [image, setImage] = useState<File | null>(null)
    const [imageUrl, setImageUrl] = useState<string>('/images/default.png')
    const [isNewCharacter, setIsNewCharacter] = useState(true)
    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    const [myCharacterSectionOpen, setMyCharacterSectionOpen] = useState(false)
    const [errorImageLength, setErrorImageLength] = useState('')
    const [characterOptions, setCharacterOptions] = useState<Options[]>()
    const { setIsModalLockedOpen } = useContext(DatasetContext)
    const [selectedCharacter, setSelectedCharacter] = useState<Npc | null>(null)
    const { mutate: uploadImage } = useUploadImage()
    const { mutate: deleteNpc } = useDeleteNpc()
    const { mutate: deleteAudio } = useDeleteAudio()
    const { mutate: uploadAudio } = useUploadAudio()
    const { mutate: uploadNpc } = useUploadNpc()

    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({ header: title, body: message, icon: icon })
        setIsModalPopupOpen(true)
    }

    useLayoutEffect(() => {
        if (characters) {
            const newCharacters = characters.map((npc) => ({
                v: npc.id.toString(),
                t: npc.name,
            }))
            setCharacterOptions(newCharacters)
            if (
                characters.find((char) => char.name === selectedCharacter?.name)
            )
                return
            setSelectedCharacter(characters[0])
            setVoiceFiles([
                {
                    file: null,
                    audioUrl: null,
                    emotion: '',
                    fileInfo: '',
                    updated: false,
                    deleted: false,
                    playing: false,
                    preset: false
                },
            ])
        }
    }, [characters])

    useLayoutEffect(() => {
        if (selectedTeam) {
            setVoiceFiles([])
            setPlayerName('')
            setBackground('')
            setIsNewCharacter(true)
            const newCharacters = characters.map((npc) => ({
                v: npc.id.toString(),
                t: npc.name,
            }))
            setCharacterOptions(newCharacters)
            setSelectedCharacter(characters[0])
        }
    }, [selectedTeam])

    useLayoutEffect(() => {
        if (selectedCharacter) {
            setIsNewCharacter(selectedCharacter.name === 'New Character')
            setPlayerName(
                selectedCharacter.name === 'New Character'
                    ? ''
                    : selectedCharacter.name
                )
            setBackground(
                selectedCharacter.background === 'New Character'
                    ? ''
                    : selectedCharacter.background
            )
            setIsNewCharacter(selectedCharacter.name === 'New Character')
            const characterImage = images?.find(
                (image) => image.name === selectedCharacter.name
            )
            setImage(null)
            if (!image) {
                setImageUrl(
                    `${characterImage?.image ?? '/images/default.png'}?nocache=${cacheTimestamp}`
                )
            }
            const characterAudio =
                audios?.filter(
                    (audio) =>
                        audio.name === selectedCharacter.name &&
                        audio.description !== 'sample'
                ) ?? []
            const newVoiceFiles = characterAudio.map((audio) => {
                return {
                    file: null,
                    audioUrl: audio.audio + `?nocache=${cacheTimestamp}`,
                    emotion: audio.description,
                    fileInfo: '',
                    updated: false,
                    deleted: false,
                    playing: false,
                    preset: audio.preset
                }
            })
            setVoiceFiles(newVoiceFiles)
        } else {
            setImageUrl('/images/default.png')
            }
        
    }, [selectedCharacter])

    const resetCharacter = () => {
        setPlayerName('')
        setBackground('')
        setImage(null)
        setImageUrl('/images/default.png')
        setVoiceFiles([])
        setSelectedCharacter(null)
    }

    const handleDeleteCharacter = (character: Options) => {
        const currentState = characterStore.getState()
        const updatedCharacters = characters.filter(
            (char) => char.name !== character.t && char.name !== 'New Character'
        )
        deleteNpc({ team: selectedTeam?.name || '', npc: character.t })
        const updatedImages = currentState.images?.filter(
            (image) => image.name !== character.t
        )
        const updatedAudios = currentState.audios?.filter(
            (audio) => audio.name !== character.t
        )

        resetCharacter()
        characterStore.setState({
            npcs: updatedCharacters,
            images: updatedImages,
            audios: updatedAudios,
        })

        openPopup('✓', 'Success', 'Character deleted successfully')
    }

    const handleDeleteNewCharacter = () => {
        resetCharacter()
    }

    const uploadData = (char: Npc) => {
        let updatedCharacter = char
        let updatedImages = images
        let updatedAudios = audios

        const timestamp = new Date().getTime()
        setCacheTimestamp(timestamp.toString())

        uploadNpc({
            team: selectedTeam?.name || '',
            npc: updatedCharacter.name,
            description: updatedCharacter.background,
            emotion: updatedCharacter.emotion,
        })

        if (image) {
            uploadImage({
                file: image,
                team: selectedTeam?.name || '',
                npc: updatedCharacter.name,
            })
            updatedImages = [
                ...images.filter(
                    (image) => image.name !== updatedCharacter.name
                ),
                {
                    name: updatedCharacter.name,
                    image: `https://custom-character-images.s3.amazonaws.com/${selectedTeam.name}/${updatedCharacter.name}/${updatedCharacter.name}.${image.name.split('.').pop()}?nocache=${timestamp}`,
                },
            ]
        }
        
        voiceFiles.forEach((voice: VoiceFile, index: number) => {
            if (voice) {
                if (voice.deleted) {
                    deleteAudio({
                        team: selectedTeam?.name || '',
                        npc: updatedCharacter.name,
                        description: voice.emotion,
                    })

                    // remove audio from updatedAudios
                    updatedAudios = updatedAudios.filter(
                        (audio) =>
                            audio.name !== updatedCharacter.name &&
                            audio.description !== voice.emotion
                    )
                    
                } else if (voice.file) {
                    uploadAudio({
                        file: voice.file,
                        team: selectedTeam?.name || '',
                        npc: updatedCharacter.name,
                        description: voice.emotion,
                    })

                    // add audio to updatedAudios
                    if (
                        updatedAudios.find(
                            (audio) =>
                                audio.name === updatedCharacter.name &&
                                audio.description === voice.emotion
                        ) === undefined
                    ) {
                        const file_extension =
                            voice?.file?.name.split('.').pop() || 'wav'
                        updatedAudios = updatedAudios.concat({
                            name: updatedCharacter.name,
                            audio: `https://custom-voices-data.s3.amazonaws.com/${selectedTeam.name}/${updatedCharacter.name}/${voice.emotion}.${file_extension}?nocache=${timestamp}`,
                            description: voice.emotion,
                        })
                    } else {
                        updatedAudios = updatedAudios.map((audio) => {
                            if (
                                audio.name === updatedCharacter.name &&
                                audio.description === voice.emotion
                            ) {
                                return {
                                    ...audio,
                                    audio: `${audio.audio}?nocache=${timestamp}`,
                                }
                            }
                            return audio
                        })
                    }
                }
            }
        })

        const updatedVoiceFiles = voiceFiles.filter((voice) => !voice.deleted)
        updatedVoiceFiles.forEach((voice) => {
            voice.updated = true
        })
        setVoiceFiles(updatedVoiceFiles)
        if (selectedCharacter?.name === 'New Character') {
            const newCharacter = {
                id: updatedCharacter.id,
                background: updatedCharacter.background,
                name: updatedCharacter.name,
                ariel_name: updatedCharacter.name,
                emotion: updatedCharacter.emotion,
                trigger_exists: updatedCharacter.trigger_exists,
                dataset_exists: updatedCharacter.dataset_exists,
                language: [
                    'Russian',
                    'Korean',
                    'Polish',
                    'French',
                    'Dutch',
                    'German',
                    'Japanese',
                    'Portuguese',
                    'Chinese',
                    'Spanish',
                    'Czech',
                    'Turkish',
                    'English',
                    'Italian',
                    'Classic Arabic',
                ],
                preset: 0,
            }
            characterStore.setState({
                npcs: [
                    ...characters.filter(
                        (character) => character.name !== 'New Character'
                    ),
                    newCharacter,
                ],
                audios: updatedAudios,
                images: updatedImages,
            })
            setSelectedCharacter(newCharacter)
        } else {
            const updatedCharacters = characters
                .filter((char) => char.name !== 'New Character')
                .map((char) => {
                    if (char.name === updatedCharacter.name) {
                        return {
                            ...char,
                            id: updatedCharacter.id,
                            background: updatedCharacter.background,
                            name: char.name,
                            ariel_name: char.ariel_name,
                            emotion: updatedCharacter.emotion,
                            trigger_exists: updatedCharacter.trigger_exists,
                            dataset_exists: updatedCharacter.dataset_exists,
                            language: char.language,
                        }
                    }
                    return char
                })
            characterStore.setState({
                npcs: updatedCharacters,
                audios: updatedAudios,
                images: updatedImages,
            })
        }
    }

    const handleSaveAllData = async () => {
        if (!playerName) {
            openPopup('⚠️', 'Error', 'Player name is required')
            return
        }

        // if any emotion is empty, show error
        if (voiceFiles.some((voice) => !voice.emotion)) {
            openPopup('⚠️', 'Error', 'Emotion is required')
            return
        }

        // if duplicate emotion names, show error
        const emotionNames = voiceFiles.map((voice) => voice.emotion)
        const uniqueEmotions = new Set(emotionNames)
        if (emotionNames.length !== uniqueEmotions.size) {
            openPopup('⚠️', 'Error', 'Emotion names must be unique')
            return
        }

        const existingCharacter = characters.find(
            (char) => char.name === playerName
        )

        const addedEmotions = voiceFiles.map((voice) => voice.emotion)
        const newCharacter: Npc = {
            id: existingCharacter
                ? existingCharacter.id
                : characters.length + 1,
            name: playerName,
            background: background,
            trigger_exists: false,
            dataset_exists: false,
            emotion: addedEmotions,
            ariel_name: playerName,
            language: selectedCharacter?.language || ['en,es,fr,de,it,pt,pl,tr,ru,nl,cs,ar,zh-cn,ja,ko'],
            preset: 0,
        }
        uploadData(newCharacter)
        openPopup('✓', 'Success', 'Character saved successfully')
    }

    return (
        <>
            {!myCharacterSectionOpen ? (
                <SectionClosed
                    rpButtonOpen={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setMyCharacterSectionOpen(true)
                        },
                    }}
                    rpIcon={'\ue902'}
                    title={'My Character Section'}
                    subtitle={'Create your own character.'}
                />
            ) : (
                <div
                    onClick={() => {
                        if (selectedTeam?.can_manage_datasets) return
                        setIsModalLockedOpen?.(true)
                    }}
                >
                    <SectionDataset
                        disabled={!selectedTeam?.can_manage_datasets}
                        rpDropdownCharacters={{
                            label: 'Choose your character',
                            options:
                                characterOptions?.filter(
                                    (char) => char.t !== 'Player'
                                ) || [],
                            value: selectedCharacter?.id.toString(),
                            onChange: (
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                                const selectedChar = characters.find(
                                    (char) =>
                                        char.id.toString() === e.target.value
                                )
                                if (selectedChar) {
                                    setSelectedCharacter(selectedChar)
                                }
                            },
                        }}
                        rpPlayerName={{
                            value: playerName,
                            onChange: (
                                e: React.ChangeEvent<HTMLTextAreaElement>
                            ) => {
                                if (!selectedTeam?.can_manage_datasets) return
                                const updatePlayerName = e.target.value
                                setPlayerName(updatePlayerName)
                            },
                            disabled:
                                !isNewCharacter ||
                                !selectedTeam?.can_manage_datasets,
                        }}
                        rpBackground={{
                            value: background,
                            onChange: (
                                e: React.ChangeEvent<HTMLTextAreaElement>
                            ) => {
                                if (!selectedTeam?.can_manage_datasets) return
                                const updatedbackground = e.target.value
                                setBackground(updatedbackground)
                            },
                        }}
                        rpImageUpload={{
                            ...(imageUrl && imageUrl !== '/images/default.png'
                                ? { exists: 'true' }
                                : {}),
                            filename: image ? image.name : '',
                            imageurl: imageUrl,
                            onClick: (e: Event) => {
                                e.preventDefault()
                                if (!selectedTeam?.can_manage_datasets) return
                                const input = document.createElement('input')
                                input.type = 'file'
                                input.accept = '.jpg,.png'
                                input.onchange = (e) => {
                                    const target = e.target as HTMLInputElement
                                    if (target.files) {
                                        const file = target.files[0]
                                        const imageTooBig =
                                            file.size > 5 * 1024 * 1024 // 5 MB in bytes
                                        if (imageTooBig) {
                                            setErrorImageLength(
                                                'Image file is too large. Please upload a file that is 5 MB or smaller.'
                                            )
                                            return
                                        }
                                        setErrorImageLength('')
                                        setImage(file)
                                        setImageUrl(URL.createObjectURL(file))
                                    }
                                }
                                input.click()
                            },
                        }}
                        rpImageDelete={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                if (!selectedTeam?.can_manage_datasets) return
                                setImage(null)
                                setImageUrl('/images/default.png')
                            },
                        }}
                        rpSaveAllData={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                if (!selectedTeam?.can_manage_datasets) return
                                handleSaveAllData()
                            },
                        }}
                        rpDeleteAllData={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault()
                                if (!selectedTeam?.can_manage_datasets) return
                                if (
                                    selectedCharacter &&
                                    selectedCharacter.name !== 'New Character'
                                ) {
                                    const deleteCharacter =
                                        characterOptions?.find(
                                            (option) =>
                                                option.t ===
                                                selectedCharacter?.name
                                        )
                                    if (deleteCharacter) {
                                        handleDeleteCharacter(deleteCharacter)
                                    }
                                }
                                // reset New Character
                                else handleDeleteNewCharacter()
                            },
                            className: `button is-large is-red ${selectedCharacter?.name === 'New Character' ? ' is-disabled' : ''}`,
                        }}
                        voiceFiles={voiceFiles}
                        setVoiceFiles={setVoiceFiles}
                        errorImageLength={errorImageLength}
                        rpCloseSection={{
                            onClick: (
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault()
                                setMyCharacterSectionOpen(false)
                            },
                        }}
                    />
                </div>
            )}
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}

import { SectionGeppetto } from 'widgets/geppetto'
import { GeppettoProvider } from './geppetto-page.model'
import { HeaderLayout } from 'devlink'
import { PageHeaderGeppetto, PopupGeppettoTutorial } from 'widgets/geppetto'

function GeppettoPageContent() {

    return (
        <>
            <HeaderLayout
                slotHeader={<PageHeaderGeppetto/>}
                slotHeaderLayout={<SectionGeppetto />}
            />
                        <PopupGeppettoTutorial/>

        </>
    )
}

export function GeppettoPage() {
    return (
        <GeppettoProvider>
            <GeppettoPageContent />
        </GeppettoProvider>
    )
}
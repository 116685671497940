import { ConfigContext } from 'app/providers/ConfigProvider'
import {
    Button,
    ButtonText,
    ForgetForm,
    LoginForm,
    SectionForgetPassword,
    SectionLogin,
    SectionSignUp,
    SignUpForm,
} from 'devlink'
import { sessionQueries } from 'entities/session'
import { useContext, useLayoutEffect, useRef, useState } from 'react'
import { Spinner } from 'shared/ui/spinner'
import { PopupNoAccess } from 'devlink'
import { useNavigate } from 'react-router-dom'
import { characterQueries } from 'entities/character'

export function LoginPage() {
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [forgotPassword, setForgotPassword] = useState(false)
    const [register, setRegister] = useState(false)
    const [sentVerificationCode, setSentVerificationCode] = useState(false)
    const [updatePassword, setUpdatePassword] = useState(false)
    const [finishedRegister, setFinishedRegister] = useState(false)
    const [finishedUpdatePassword, setFinishedUpdatePassword] = useState(false)
    const [showNoAccessPopup, setShowNoAccessPopup] = useState(false);
    const [userId, setUserId] = useState('')
    const [acceptTerms, setAcceptTerms] = useState(false);
    const submitButton = useRef<HTMLButtonElement>(null)
    const config = useContext(ConfigContext)
    const [email, setEmail] = useState('')
    const [info, setInfo] = useState<string | object | undefined>({})
    const navigate = useNavigate()

    const {
        mutate: loginUser,
        isPending: isPendingLogin,
        isError: isErrorLogin,
        isSuccess: isSuccessLogin,
        error: errorLogin,
    } = sessionQueries.useLoginUserMutation()

    const {
        mutate: getTeams,
        isPending: getTeamsPending,
        isError: getTeamsError,
        isSuccess: getTeamsSuccess,
        error: getTeamsErrorData,
    } = sessionQueries.useGetTeamsMutation()

    const {
        mutate: getKeys,
        isPending: getKeysPending,
    } = sessionQueries.useGetKeysMutation(true)
    

    const {
        mutate: forgetPassword,
        isError: isErrorPassword,
        error: errorForgetPassword,
        data: responseForgetPassword,
        isSuccess: isSuccessForgetPassword,
    } = sessionQueries.useForgetPasswordMutation()

    const {
        mutate: resendCode,
    } = sessionQueries.useResendMutation()

    const {
        mutate: registerUser,
        isError: isErrorRegister,
        data: responseRegister,
        isSuccess: isSuccessRegister,
        error: errorRegister,
    } = sessionQueries.useRegisterMutation()

    const { mutate: logout, isPending: isLogoutPending } =
        sessionQueries.useLogoutMutation()

            const {
                mutate: getNpcs,
            } = characterQueries.useNpcsService()
    function handleSubmit(
        e:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent
            | React.KeyboardEvent
    ) {
        e.preventDefault()
        sessionStorage.setItem(
            'user',
            JSON.stringify({
                email: email,
                user: userId,
            })
        )
        !isPendingLogin && loginUser({ user: { email: email, password } })
    }

    useLayoutEffect(() => {
        logout()
    }, [])

    useLayoutEffect(() => {
        if (isSuccessLogin) {
            getTeams()
        }
    }, [isSuccessLogin])

    useLayoutEffect(() => {
        if (getTeamsError) {
            console.log('getTeamsError', getTeamsErrorData)
        }
        if (getTeamsSuccess) {
            getKeys()
            getNpcs()
        }
    }, [getTeamsSuccess, getTeamsError])

    useLayoutEffect(() => {
        if (isErrorRegister) {
            setInfo({ error: JSON.parse((errorRegister as any).response).detail })
        }
    }, [isErrorRegister])

    useLayoutEffect(() => {
        if (isSuccessRegister && responseRegister) {
            setSentVerificationCode(true)
        }
    }, [responseRegister])

    useLayoutEffect(() => {
        if (isErrorLogin) {
            setInfo({ error: JSON.parse((errorLogin as any).response).detail })
        }
    }, [isErrorLogin])

    useLayoutEffect(() => {
        if (getTeamsError) {
            setShowNoAccessPopup(true);
        }
    }, [getTeamsError]);

    const sendForgotPassword = () => {
        forgetPassword({ user: { email: email, password: '' } })
    }

    const resendForgotPassword = () => {
        resendCode({ user: { email: email, password: '' }, method: 'forgetPassword' })
    }

    const resendSignUp = () => {
        resendCode({ user: { email: email, password: '' }, method: 'signUp' })
        setSentVerificationCode(true)
    }


    const sendRegistration = () => {
        if (!acceptTerms) {
            setInfo({ error: 'You must accept the Terms & Conditions.' })
            return
        }
        if (password !== repeatPassword) {
            setInfo({ error: 'Passwords do not match.' })
            return
        } else if (password.length < 8) {
            setInfo({ error: 'Password must be at least 8 characters.' })
            return
        }
        setInfo(undefined)
        registerUser({ user: { email: email, password: password }, name: name, address: '157 Bd Macdonald, Paris', birthdate: '1992-08-12' })
    }

    useLayoutEffect(() => {
        if (responseRegister) {
            setUserId(responseRegister.uid)
        }
    }, [responseRegister])

    useLayoutEffect(() => {
        if (isSuccessForgetPassword && responseForgetPassword) {
            setSentVerificationCode(true)
        }
    }, [responseForgetPassword])

    useLayoutEffect(() => {
        if (forgotPassword === false) {
            // setInfo('Password was updated! Please login now.')
        }
    }, [forgotPassword])

    useLayoutEffect(() => {
        if (finishedRegister) {
            setInfo({ message: 'You successfully signed up. Welcome to the X&Immersion family!' })
        }
    }, [finishedRegister])

    useLayoutEffect(() => {
        if (finishedUpdatePassword) {
            setInfo({ message: 'Password was updated! Please login now.' })
        }
    }, [finishedUpdatePassword])

    if (showNoAccessPopup) {
        return <PopupNoAccess />
    }

    return (
        <div style={{ width: '100%' }}>
            {register && (
                <SectionSignUp
                    slotSignUpForm={
                        <SignUpForm
                            rpLoginName={{
                                type: 'text',
                                value: name,
                                onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setName(e.target.value),
                            }}
                            rpLoginEmail={{
                                type: 'email',
                                value: email,
                                onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setEmail(e.target.value),
                            }}
                            rpLoginPassword={{
                                type: 'password',
                                value: password,
                                onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setPassword(e.target.value),
                            }}
                            rpRepeatPassword={{
                                type: 'password',
                                value: repeatPassword,
                                onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setRepeatPassword(e.target.value),
                            }}
                            rpButton={{
                                className: 'button is-large',
                                onClick: () => {
                                    sendRegistration()
                                },
                            }}
                            rpCheckTermsAndConditions={{
                                type: 'checkbox',
                                value: acceptTerms,
                                onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setAcceptTerms(true),
                            }}
                            sentVerificationCode={sentVerificationCode}
                            setAcceptTerms={setAcceptTerms}
                            setSentVerificationCode={setSentVerificationCode}
                            setRegister={setRegister}
                            info={info}
                            setInfo={setInfo}
                            password={password}
                            setFinishedRegister={setFinishedRegister}
                            userId={userId}
                            rpResendCode={{
                                onClick: () => resendSignUp(),
                            }}
                        />
                    }
                />
            )}
            {forgotPassword && (
                <SectionForgetPassword
                    slotForgetForm={
                        <ForgetForm
                            rpLoginEmail={{
                                type: 'email',
                                value: email,
                                onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setEmail(e.target.value),
                            }}
                            rpButton={{
                                className: 'button is-large',
                                onClick: () => sendForgotPassword(),
                            }}
                            sentVerificationCode={sentVerificationCode}
                            setSentVerificationCode={setSentVerificationCode}
                            updatePassword={updatePassword}
                            setUpdatePassword={setUpdatePassword}
                            setForgotPassword={setForgotPassword}
                            setFinishedUpdatePassword={setFinishedUpdatePassword}
                            errorPassword={{ isError: isErrorPassword, message: errorForgetPassword }}
                            rpResendCode={{
                                onClick: () => resendForgotPassword(),
                            }}
                        />
                    }
                />
            )}
            {!forgotPassword && !register && (
                <>
                    <SectionLogin
                        slotLoginForm={
                            <form onSubmit={handleSubmit}>
                                <LoginForm
                                    rpLoginEmail={{
                                        type: 'email',
                                        value: email,
                                        onChange: (
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => setEmail(e.target.value),
                                    }}
                                    rpLoginPassword={{
                                        type: 'password',
                                        value: password,
                                        onChange: (
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => setPassword(e.target.value),
                                    }}
                                    slotLoginBtn={
                                        <Button
                                            rpButton={{
                                                className: `button is-large ${isPendingLogin && 'is-disabled'}`,
                                                onClick: (
                                                    e: React.MouseEvent
                                                ) => {
                                                    e.preventDefault()
                                                    submitButton.current?.click()
                                                    setFinishedUpdatePassword(false)
                                                    setFinishedRegister(false)
                                                },
                                                onKeyPress: (
                                                    e: React.KeyboardEvent
                                                ) => {
                                                    if (e.key === 'Enter') {
                                                        submitButton.current?.click()
                                                        setFinishedUpdatePassword(false)
                                                        setFinishedRegister(false)
                                                    }
                                                },
                                            }}
                                            slotButton={
                                                <>
                                                    {isPendingLogin || getTeamsPending || getKeysPending ? (
                                                        <Spinner />
                                                    ) : (
                                                        <ButtonText buttonText="Login" />
                                                    )}
                                                </>
                                            }
                                        />
                                    }
                                    rpLoginPasswordForget={{
                                        onClick: () => setForgotPassword(true),
                                    }}
                                    rpSignUp={{
                                        onClick: () =>
                                            setRegister(true),
                                    }}
                                    loginFormErrorVisibility={isErrorLogin}
                                    info={info}
                                    rpVerify={{
                                        onClick: () => {
                                            setRegister(true)
                                            setSentVerificationCode(true)
                                        },
                                    }}
                                />
                                <button
                                    type="submit"
                                    className="hide"
                                    ref={submitButton}
                                />
                            </form>
                        }
                    />
                </>
            )}
        </div>
    )
}
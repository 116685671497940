import React from 'react'
import * as _Builtin from '../_Builtin'

export function ProgressBar({
    as: _Component = _Builtin.Link,
    bgcolor = '#e0e0de',
    completed = 60,
}) {
    const containerStyles = {
        height: 20,
        width: '100%',
        backgroundColor: '#5d6c7b',
        borderRadius: 50,
        marginTop: 20,
        marginBottom: 20,
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'center',
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'lighter',
    }

    return (
        <_Component
            className="progress-bar padding-vertical padding-tiny"
            button={false}
            block="inline"
            options={{
                href: '#',
            }}
        >
            <div 
            
            style={containerStyles}>
                <div style={fillerStyles}>
                    <span style={labelStyles}>{`${completed}%`}</span>
                </div>
            </div>
        </_Component>
    )
}

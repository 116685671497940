import { createJsonMutation } from 'shared/lib/fetch'
import { arielBaseUrl } from 'shared/api/ximmersion'
import { diagenBaseUrl } from 'shared/api/ximmersion'
import { zodContract } from 'shared/lib/zod'
import { CreateVoiceRecordResponseSchema } from './voice-record.contracts'
import { TextTranslationResponseSchema } from './voice-record.contracts'
import { CreateVoiceRecordDto } from './voice-record.types'
import { CreateTextTranslation } from './voice-record.types'
import { mapVoiceRecord } from './voice-record.lib'
import { sessionModel } from 'entities/session'

/**
 * @description Mutation function for creating a voice record.
 *
 * @param params - Parameters for creating a voice record.
 * @returns A promise that resolves to the result of the mutation.
 */
export async function createVoiceRecordMutation(params: {
    voiceRecord: CreateVoiceRecordDto
    language: string
    npcName: string
}) {
    return createJsonMutation({
        request: {
            url: arielBaseUrl(`/tts/${params.npcName}`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({system: 'ariel'}) },
            body: JSON.stringify({
                ...params.voiceRecord,
                language: params.language,
                length: true,
            }),
        },
        response: {
            contract: zodContract(CreateVoiceRecordResponseSchema),
            mapData: (res) => mapVoiceRecord(params.voiceRecord, res),
        },
    })
}


    export async function createTextTranslationMutation(params: {
      textTranslation: CreateTextTranslation;
    }) {    
      return createJsonMutation({
        request: {
            url: diagenBaseUrl('/translate'),
          method: 'POST',
          headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
          body: JSON.stringify({
            ...params.textTranslation,
          }),
        },
        response: {
          contract: zodContract(TextTranslationResponseSchema),
        },
      });
    }
import React from "react";
import * as _Builtin from "../_Builtin";
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'

export function DemosGeppetto({
    as: _Component = _Builtin.Block,
    rpButtonUnityGeppettoDemoScene,
    rpUserAccessRights,
    rpButtonVideoTutorialGeppettoUnity,
    rpButtonUnrealGeppettoDemoSceneUE2,
    rpButtonUnrealGeppettoDemoSceneUE3,
    rpButtonUnrealGeppettoDemoSceneUE4,
    rpButtonUnrealGeppettoDemoSceneUE5,
    rpButtonVideoTutorialGeppettoUnreal,
}) {

    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block className="padding-horizontal padding-small container-large grid-2col" tag="div">
                <_Builtin.Block className="light-border rounded-8" tag="div">
                    <_Builtin.Block className="background-variant-blue200 rounded-8" tag="div">
                        {/* Conteneur parent en grille */}
                        <_Builtin.Block className="encarts-container gap-large" tag="div">
                            {/* Partie principale */}
                            <_Builtin.Block className="grid-layout-container rounded-8" tag="div">
                                {/* Partie gauche : Logo */}
                                <_Builtin.Block className="logo-container" tag="div">
                                    <_Builtin.Image
                                        className="logo-animation-unreal"
                                        width="auto"
                                        height="auto"
                                        alt=""
                                        src="/images/logo/unrealengine.png"
                                    />
                                </_Builtin.Block>

                                {/* Partie droite : Contenu textuel */}
                                <_Builtin.Block className="content-container padding padding-medium" tag="div">
                                    <_Builtin.Paragraph className="text-weight-semibold text-size-large flex-center">
                                        {'Unreal Demo'}
                                    </_Builtin.Paragraph>
                                    <_Builtin.Paragraph className="text-color-light-grey flex-center">
                                        {'Geppetto and Ariel Package'}
                                    </_Builtin.Paragraph>
                                    {/* Bouton "Video Tutorial" */}
                                    <_Builtin.Link
                                        className="button is-primary"
                                        button={false}
                                        {...rpButtonVideoTutorialGeppettoUnreal}
                                    >
                                        <_Builtin.Block className="slot-button" tag="div">
                                            <ButtonIcon buttonIconText="" />
                                            <ButtonText buttonText="Video Tutorial" />
                                        </_Builtin.Block>
                                    </_Builtin.Link>
                                    {rpUserAccessRights.can_download_gepetto === 1 ? (
                                        <>
                                            {/* Boutons de téléchargement */}
                                            <_Builtin.Block className="margin-top margin-medium" tag="div">
                                                <_Builtin.Block className="grid-2col flex-center" tag="div">
                                                    <_Builtin.Paragraph className="cursor-pointer" {...rpButtonUnrealGeppettoDemoSceneUE2}>
                                                        {'UE 5.2'}
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Link className="button is-secondary" button={false} {...rpButtonUnrealGeppettoDemoSceneUE2}>
                                                        <_Builtin.Block className="slot-button" tag="div">
                                                            <ButtonIcon buttonIconText="" />
                                                            <ButtonText buttonText="Download" />
                                                        </_Builtin.Block>
                                                    </_Builtin.Link>
                                                </_Builtin.Block>
                                                <_Builtin.Block className="grid-2col flex-center margin-top margin-tiny" tag="div">
                                                    <_Builtin.Paragraph className="cursor-pointer" {...rpButtonUnrealGeppettoDemoSceneUE3}>
                                                        {'UE 5.3'}
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Link className="button is-secondary" button={false} {...rpButtonUnrealGeppettoDemoSceneUE3}>
                                                        <_Builtin.Block className="slot-button" tag="div">
                                                            <ButtonIcon buttonIconText="" />
                                                            <ButtonText buttonText="Download" />
                                                        </_Builtin.Block>
                                                    </_Builtin.Link>
                                                </_Builtin.Block>
                                                <_Builtin.Block className="grid-2col flex-center margin-top margin-tiny" tag="div">
                                                    <_Builtin.Paragraph className="cursor-pointer" {...rpButtonUnrealGeppettoDemoSceneUE4}>
                                                        {'UE 5.4'}
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Link className="button is-secondary" button={false} {...rpButtonUnrealGeppettoDemoSceneUE4}>
                                                        <_Builtin.Block className="slot-button" tag="div">
                                                            <ButtonIcon buttonIconText="" />
                                                            <ButtonText buttonText="Download" />
                                                        </_Builtin.Block>
                                                    </_Builtin.Link>
                                                </_Builtin.Block>
                                                <_Builtin.Block className="grid-2col flex-center margin-top margin-tiny" tag="div">
                                                    <_Builtin.Paragraph className="cursor-pointer" {...rpButtonUnrealGeppettoDemoSceneUE5}>
                                                        {'UE 5.5'}
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Link className="button is-secondary" button={false} {...rpButtonUnrealGeppettoDemoSceneUE5}>
                                                        <_Builtin.Block className="slot-button" tag="div">
                                                            <ButtonIcon buttonIconText="" />
                                                            <ButtonText buttonText="Download" />
                                                        </_Builtin.Block>
                                                    </_Builtin.Link>
                                                </_Builtin.Block>
                                            </_Builtin.Block>
                                        </>
                                    ) : (
                                        /* Message si pas d'accès */
                                        <_Builtin.Block className="textposition margin-vertical margin-large" tag="div">
                                            <_Builtin.Paragraph className="text-color-light-grey">
                                                {'You want to get access to the demo on Unreal?'}
                                            </_Builtin.Paragraph>
                                            <_Builtin.Paragraph className="text-color-light-grey">
                                                {'Contact us at '}
                                                <a href="mailto:bastien.s@xandimmersion.com">
                                                    bastien.s@xandimmersion.com
                                                </a>
                                                {' and someone from our team will answer you!'}
                                            </_Builtin.Paragraph>
                                        </_Builtin.Block>
                                    )}
                                </_Builtin.Block>
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                </_Builtin.Block>
                <_Builtin.Block className="light-border rounded-8" tag="div">
                    <_Builtin.Block className="background-variant-blue200 rounded-8" tag="div">
                        <_Builtin.Block className="encarts-container gap-large" tag="div">
                            {/* Partie principale */}
                            <_Builtin.Block className="grid-layout-container rounded-8" tag="div">
                                {/* Partie gauche : Logo */}
                                <_Builtin.Block className="logo-container" tag="div">
                                    <_Builtin.Image
                                        className="logo-animation-unity"
                                        width="auto"
                                        height="auto"
                                        alt=""
                                        src="/images/logo/unity.png"
                                    />
                                </_Builtin.Block>

                                {/* Partie droite : Contenu textuel */}
                                <_Builtin.Block className="content-container padding padding-medium" tag="div">
                                    <_Builtin.Paragraph className="text-weight-semibold text-size-large flex-center">
                                        {'Unity Demo'}
                                    </_Builtin.Paragraph>
                                    <_Builtin.Paragraph className="text-color-light-grey flex-center">
                                        {'Geppetto and Ariel Package'}
                                    </_Builtin.Paragraph>
                                    {/* Bouton "Video Tutorial" */}
                                    <_Builtin.Link
                                        className="button is-primary"
                                        button={false}
                                        {...rpButtonVideoTutorialGeppettoUnity}
                                    >
                                        <_Builtin.Block className="slot-button" tag="div">
                                            <ButtonIcon buttonIconText="" />
                                            <ButtonText buttonText="Video Tutorial" />
                                        </_Builtin.Block>
                                    </_Builtin.Link>

                                    {rpUserAccessRights.can_download_gepetto === 1 ? (
                                        <>
                                            {/* Boutons de téléchargement */}
                                            <_Builtin.Block className="grid-2col flex-center margin-vertical margin-xxlarge" tag="div">
                                                <_Builtin.Paragraph
                                                    className="cursor-pointer"
                                                    {...rpButtonUnityGeppettoDemoScene}
                                                >
                                                    {'Unity 2021.3'}
                                                </_Builtin.Paragraph>
                                                <_Builtin.Link
                                                    className="button is-secondary"
                                                    button={false}
                                                    {...rpButtonUnityGeppettoDemoScene}
                                                >
                                                    <_Builtin.Block className="slot-button" tag="div">
                                                        <ButtonIcon buttonIconText="" />
                                                        <ButtonText buttonText="Download" />
                                                    </_Builtin.Block>
                                                </_Builtin.Link>
                                            </_Builtin.Block>

                                        </>
                                    ) : (
                                        /* Message alternatif si pas d'accès */
                                        <_Builtin.Block className="textposition margin-vertical margin-large" tag="div">
                                            <_Builtin.Paragraph className="text-color-light-grey">
                                                {'You want to get access to the demo on Unity?'}
                                            </_Builtin.Paragraph>
                                            <_Builtin.Paragraph className="text-color-light-grey">
                                                {'Contact us at '}
                                                <a href="mailto:bastien.s@xandimmersion.com">
                                                    bastien.s@xandimmersion.com
                                                </a>
                                                {' and someone from our team will answer you!'}
                                            </_Builtin.Paragraph>
                                        </_Builtin.Block>
                                    )}
                                </_Builtin.Block>
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component >
    )
}
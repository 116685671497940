import React, { useState, useLayoutEffect, useEffect } from 'react'
import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'

export function PopupLogsTutorial({
    as: _Component = _Builtin.Block,
    rpClosePopUp = {},
    rpButtonCancel = {},
    visibility = true,
}) {
    const videos = [
        {
            src: 'https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/TUTORIAL+-+LOG+ANALYSIS.mp4',
            type: 'video/mp4',
        },
    ]

    const [currentIndex, setCurrentIndex] = useState(0)


    return visibility ? (
        <_Component className="popup-overlay-best" tag="div">
            <_Builtin.Block
                className="popup-box-best margin-medium rounded-8 overflow-scroll-navbar"
                tag="div"
            >
                <_Builtin.Block
                    className="popup-top-best background-color-blue200 padding-xsmall"
                    tag="div"
                    {...rpClosePopUp}
                >
                    <_Builtin.Block
                        className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
                        tag="div"
                    >
                        {''}
                    </_Builtin.Block>
                    <_Builtin.Heading className="text-align-center" tag="h2">
                        {'Logan Tutorial'}
                    </_Builtin.Heading>

                    <_Builtin.Block
                        className="close-popup"
                        tag="div"
                        {...rpClosePopUp}
                    >
                        {'\ue91e'}
                    </_Builtin.Block>
                </_Builtin.Block>
                <_Builtin.Block
                    className="popup-body-best padding padding-medium background-blue-150 z-index-1"
                    tag="div"
                >
                    <video
                        className="flex-center padding-horizontal padding-tiny"
                        autoPlay
                        controls
                        height={450}
                        width={900}
                        preload="auto"
                    >
                        <source
                            src={videos[currentIndex].src}
                            type={videos[currentIndex].type}
                        />
                    </video>
                    <_Builtin.Block
                        className="button-group margin-top margin-xxsmall"
                        tag="div"
                        {...rpButtonCancel}
                    >
                        <_Builtin.Link
                            className="button is-large is-transparent"
                            button={false}
                            block="inline"
                            options={{
                                href: '#',
                            }}
                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonIcon buttonIconText="" />
                                <ButtonText buttonText="Cancel" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    ) : null
}

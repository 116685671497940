import { AdminContext } from 'pages/admin/admin-page.model'
import { useContext, useLayoutEffect, useState } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import Select from 'react-select'
import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'

createTheme(
    'createTableTheme',
    {
        text: {
            primary: '#f7f7f7',
            secondary: '#727eab',
        },
        background: {
            default: '#2b3162',
        },
        striped: {
            default: '#C62828',
            selected: '#C62828',
        },
        context: {
            background: '#C62828',
            text: '#C62828',
        },
        divider: {
            default: '#878b9b',
        },
        highlightOnHover: {
            default: '#404a8e',
            text: '#f7f7f7',
        },
    },
    'dark'
)

export function DisplayUsers({
    as: _Component = _Builtin.Section,
    users,
    teamOptions,
    disabled,
    loading,
    isOwner,
}) {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    useLayoutEffect(() => {
        console.log('users', users)
        if (users.length > 0) {
            const columns = [
                {
                    name: 'Name',
                    selector: (row) => row.name,
                    sortable: true,
                    // grow: 2,
                },
                {
                    name: 'Email',
                    selector: (row) => row.email,
                    sortable: true,
                    // grow: 2,
                },
                {
                    name: 'Date Joined',
                    selector: (row) => row.date_joined,
                    sortable: true,
                    // grow: 1,
                },
                {
                    name: 'Valid from',
                    selector: (row) => row.valid_from,
                    sortable: true,
                    // grow: 1,
                },
                {
                    name: 'Valid until',
                    selector: (row) => row.valid_until,
                    sortable: true,
                    // grow: 1,
                },
            ]
            setColumns(columns)
            setData(users)
        } else {
            setColumns([])
            setData([])
        }
    }, [users])

    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block
                className="container-large padding-horizontal padding-small rounded-8"
                tag="div"
            >
                <_Builtin.Block
                    className="section-title-wrapper margin-bottom margin-xsmall rounded-8"
                    tag="div"
                >
                    <_Builtin.Heading tag="h2">
                        {'Display Users'}
                    </_Builtin.Heading>
                </_Builtin.Block>
                <DataTable
                    className="dropdown"
                    // Documentation: https://react-data-table-component.netlify.app/?path=/docs/getting-started-kitchen-sink--docs
                    columns={columns}
                    data={data}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                    expandableRowsComponentProps={{
                        saveProps: {
                            onClick: (data) => console.log('data', data.target),
                        },
                        TeamsDropdown: {
                            placeholder: 'Choose target teams...',
                            isDisabled: false,
                            options: teamOptions,
                        },
                    }}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    highlightOnHover
                    pagination
                    pointerOnHover
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    theme="createTableTheme"
                    disabled={disabled}
                    progressPending={loading}
                />
            </_Builtin.Block>
        </_Component>
    )
}

const ExpandableRowComponent = ({
    data,
    saveProps,
    TeamsDropdown,
    isOwner,
}) => {
    const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([])
    const customStyles = {
        control: (provided) => ({
            ...provided,
            marginBottom: '0px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'hsla(0, 0%, 100%, 0)',
            borderRadius: '4px',
            backgroundColor: '#262c47',
            color: 'white',
            fontSize: '1rem',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#262c47',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#262c47'
                : state.isFocused
                  ? '#404a8e'
                  : '#262c47',
            color: state.isSelected ? 'lightgrey' : 'white',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#727eab',
            borderRadius: '4px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'white',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'white',
            ':hover': {
                backgroundColor: '#878b9b',
                color: 'white',
            },
        }),
        input: (provided) => ({
            ...provided,
            color: '#878b9b', // Color of the typed text
        }),
        // placeholder: (provided) => ({
        //     ...provided,
        //     color: 'white', // Color of the placeholder text
        // }),
    }
    const {
        setIsConfirmationAddOpen,
        setIsConfirmAccessRightsOpen,
        setTargetTeamsList,
        setUserAccessRights,
        setUpdateUserName,
    } = useContext(AdminContext)
    const [accessRights, setAccessRights] = useState({
        is_owner: data.is_owner,
        is_admin: data.is_admin,
        can_manage_npcs: data.can_manage_npcs,
        can_manage_models: data.can_manage_models,
        can_manage_datasets: data.can_manage_datasets,
        can_download_gepetto: data.can_download_gepetto,
        can_download_ariel: data.can_download_ariel,
        can_download_diagen: data.can_download_diagen,
        valid_until: data.valid_until,
        valid_from: data.valid_from,
    })

    return (
        <_Builtin.Block
            className="flex-center flex gap-8px padding padding-small rounded-8"
            tag="div"
        >
            <_Builtin.Block className="npc-form padding-small" tag="div">
                <_Builtin.FormBlockLabel
                    className="text-size-medium margin-bottom margin-xxsmall"
                    htmlFor="NPC-3"
                >
                    {'Access Right (current Team):'}
                </_Builtin.FormBlockLabel>
                <_Builtin.Block
                    className="light-border margin-bottom margin-small"
                    tag="div"
                >
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="is_admin"
                            data-name="is_admin"
                            checked={data.is_admin}
                            required={false}
                            id="is_admin"
                            form={{
                                type: 'is_admin-input',
                                name: 'is_admin',
                            }}
                            inputtype="custom"
                            customclassName="w-checkbox-input--inputtype-custom"
                            onChange={() => {
                                setAccessRights({
                                    ...accessRights,
                                    is_admin: !accessRights.is_admin,
                                })
                            }}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Is Admin'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="can_manage_npcs"
                            data-name="can_manage_npcs"
                            checked={data.can_manage_npcs}
                            required={false}
                            id="can_manage_npcs"
                            form={{
                                type: 'can_manage_npcs-input',
                                name: 'can_manage_npcs',
                            }}
                            inputtype="custom"
                            customclassName="w-checkbox-input--inputtype-custom"
                            onChange={() => {
                                setAccessRights({
                                    ...accessRights,
                                    can_manage_npcs:
                                        !accessRights.can_manage_npcs,
                                })
                            }}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Can Manage NPCs'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="can_manage_models"
                            data-name="can_manage_models"
                            checked={data.can_manage_models}
                            required={false}
                            id="can_manage_models"
                            form={{
                                type: 'can_manage_models-input',
                                name: 'can_manage_models',
                            }}
                            inputtype="custom"
                            customclassName="w-checkbox-input--inputtype-custom"
                            onChange={() => {
                                setAccessRights({
                                    ...accessRights,
                                    can_manage_models:
                                        !accessRights.can_manage_models,
                                })
                            }}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Can Manage Models'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="can_manage_datasets"
                            data-name="can_manage_datasets"
                            checked={data.can_manage_datasets}
                            required={false}
                            id="can_manage_datasets"
                            form={{
                                type: 'can_manage_datasets-input',
                                name: 'can_manage_datasets',
                            }}
                            inputtype="custom"
                            customclassName="w-checkbox-input--inputtype-custom"
                            onChange={() => {
                                setAccessRights({
                                    ...accessRights,
                                    can_manage_datasets:
                                        !accessRights.can_manage_datasets,
                                })
                            }}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Can Manage Datasets'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="can_download_gepetto"
                            data-name="can_download_gepetto"
                            checked={data.can_download_gepetto}
                            required={false}
                            id="can_download_gepetto"
                            form={{
                                type: 'can_download_gepetto-input',
                                name: 'can_download_gepetto',
                            }}
                            inputtype="custom"
                            customclassName="w-checkbox-input--inputtype-custom"
                            onChange={() => {
                                setAccessRights({
                                    ...accessRights,
                                    can_download_gepetto:
                                        !accessRights.can_download_gepetto,
                                })
                            }}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Can Download Gepetto'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="can_download_ariel"
                            data-name="can_download_ariel"
                            checked={data.can_download_ariel}
                            required={false}
                            id="can_download_ariel"
                            form={{
                                type: 'can_download_ariel-input',
                                name: 'can_download_ariel',
                            }}
                            inputtype="custom"
                            customclassName="w-checkbox-input--inputtype-custom"
                            onChange={() => {
                                setAccessRights({
                                    ...accessRights,
                                    can_download_ariel:
                                        !accessRights.can_download_ariel,
                                })
                            }}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Can Download Ariel'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="can_download_diagen"
                            data-name="can_download_diagen"
                            checked={data.can_download_diagen}
                            required={false}
                            id="can_download_diagen"
                            form={{
                                type: 'can_download_diagen-input',
                                name: 'can_download_diagen',
                            }}
                            inputtype="custom"
                            customclassName="w-checkbox-input--inputtype-custom"
                            onChange={() => {
                                setAccessRights({
                                    ...accessRights,
                                    can_download_diagen:
                                        !accessRights.can_download_diagen,
                                })
                            }}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Can Download Diagen'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                    <_Builtin.Block className="grid-2col">
                        <_Builtin.FormInlineLabel >
                            {'Valid from: ' +
                                accessRights.valid_from.split(' ')[0]}

                            <_Builtin.FormTextInput
                                type="date"
                                name="valid_from"
                                data-name="valid_from"
                                value={accessRights.valid_until}
                                required={false}
                                id="valid_from"
                                form={{
                                    type: 'valid_from-input',
                                    name: 'valid_from',
                                }}
                                inputtype="custom"
                                customclassName="w-input"
                                onChange={(e) => {
                                    setAccessRights({
                                        ...accessRights,
                                        valid_from:
                                            e.target.value + ' 00:00:00',
                                    })
                                }}
                            />
                        </_Builtin.FormInlineLabel>
                        <_Builtin.FormInlineLabel className="">
                            {'Valid until: ' +
                                accessRights.valid_until.split(' ')[0]}

                            <_Builtin.FormTextInput
                                type="date"
                                name="valid_until"
                                data-name="valid_until"
                                value={accessRights.valid_until}
                                required={false}
                                id="valid_until"
                                form={{
                                    type: 'valid_until-input',
                                    name: 'valid_until',
                                }}
                                inputtype="custom"
                                customclassName="w-input"
                                onChange={(e) => {
                                    setAccessRights({
                                        ...accessRights,
                                        valid_until:
                                            e.target.value + ' 00:00:00',
                                    })
                                }}
                            />
                        </_Builtin.FormInlineLabel>
                    </_Builtin.Block>
                </_Builtin.Block>

                <_Builtin.Block className="button-group" tag="div">
                    <_Builtin.Link
                        className="button"
                        button={false}
                        block="inline"
                        options={{
                            href: '#',
                        }}
                        onClick={() => {
                            setUserAccessRights(accessRights)
                            setUpdateUserName(data.id)
                            setIsConfirmAccessRightsOpen(true)
                        }}
                    >
                        <_Builtin.Block className="slot-button" tag="div">
                            <ButtonIcon buttonIconText="&#xe914;" />
                            <ButtonText buttonText="Change Access Rights" />
                        </_Builtin.Block>
                    </_Builtin.Link>
                </_Builtin.Block>
            </_Builtin.Block>
            {TeamsDropdown.options && TeamsDropdown.options.length > 0 && (
                <_Builtin.Block className="npc-form padding-small" tag="div">
                    <_Builtin.FormBlockLabel
                        className="text-size-medium margin-bottom margin-xxsmall"
                        htmlFor="NPC-3"
                    >
                        {'Allow User for Teams:'}
                    </_Builtin.FormBlockLabel>
                    <_Builtin.Block
                        className="light-border margin-bottom margin-small"
                        tag="div"
                    >
                        <Select
                            {...TeamsDropdown}
                            value={selectedTeamsOptions}
                            onChange={(selectedOptions) => {
                                setSelectedTeamsOptions(selectedOptions)
                            }}
                            closeMenuOnSelect={false}
                            isMulti
                            styles={customStyles}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#878b9b',
                                    primary: 'black',
                                },
                            })}
                        />
                    </_Builtin.Block>
                    <_Builtin.Block className="button-group" tag="div">
                        <_Builtin.Link
                            className="button"
                            button={false}
                            block="inline"
                            options={{
                                href: '#',
                            }}
                            onClick={() => {
                                setTargetTeamsList(
                                    // only take the label of the selected options
                                    selectedTeamsOptions.map(
                                        (option) => option.label
                                    )
                                )
                                setIsConfirmationAddOpen(true)
                                setUpdateUserName(data.id)
                            }}
                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonIcon buttonIconText="&#xe914;" />
                                <ButtonText buttonText="Assign Team" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                    </_Builtin.Block>
                </_Builtin.Block>
            )}
        </_Builtin.Block>
    )
}

import { PopupDemosTutorial } from 'devlink'
import { DemosContext } from 'pages/demos/demos-page.model'
import React, { useContext, useEffect, useCallback } from 'react';

export function PopupDemosTutorialContainer() {
    const {
        isModalTutorialOpen,
        setIsModalTutorialOpen,
        setIsTutorialTriggered,
    } = useContext(DemosContext)

    const handleClosePopup = useCallback(() => {
        setIsModalTutorialOpen?.(false);
    }, [setIsModalTutorialOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    return (
        <PopupDemosTutorial
            visibility={isModalTutorialOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault();
                    setIsModalTutorialOpen?.(false);
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalTutorialOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalTutorialOpen?.(false)
                    setIsTutorialTriggered?.(true)
                },
            }}
        />
    )
}
import { z } from 'zod'

export const CreateVoiceRecordDtoSchema = z.object({
    sentence: z.string(),
    convergence: z.number(),
    translate: z.boolean(),
    speed: z.number().optional(),
    emotion: z.string().optional(),
})

export const CreateTextTranslationDtoSchema = z.object({
    text: z.string(),
    language: z.string(),
    origin_language: z.string().optional(),
})

export const TextTranslationResponseSchema = z.object({
    response: z.string(),
    input_text: z.string().optional(),
    language: z.string().optional(),
})

export const CreateVoiceRecordResponseSchema = z.object({
    sentence: z.string(),
    emotion: z.string().optional(),
    length: z.number().optional(),
    translation: z.string().optional(),
    audio: z.string(),
})

export const VoiceRecordSchema = z.object({
    sentence: z.string(),
    convergence: z.number(),
    translation: z.string().optional(),
    length: z.number().optional(),
    audio: z.string().optional(),
    speed: z.number().optional(),
    emotion: z.string().optional(),
})

export const VoiceRecordsSchema = z.array(VoiceRecordSchema)

import React, { useEffect, useState } from 'react'
import * as _Builtin from '../_Builtin'
import { ButtonText } from '../_Component/ButtonText'

export function PopupDiagenTutorial({
    as: _Component = _Builtin.Block,
    rpClosePopUp = {},
    rpButtonCancel = {},
    visibility = true,
}) {
    const [currentIndex, setCurrentIndex] = useState(0)

    const videos = [
        {
            url: 'https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/TUTORIAL+-+DIAGEN+PART+1+-+CLASSIC+GENERATION.mp4',
            title: 'Part 1 - Classic Dialog Generation',
        },
        {
            url: 'https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/TUTORIAL+-+DIAGEN+PART+2+-+UPLOAD+DIAGEN+FILES.mp4',
            title: 'Part 2 - Upload Diagen Files',
        },
    ]

    // Gestion des clics sur les boutons
    const handleVideoSelection = (index) => {
        setCurrentIndex(index)
    }

    //Au clic sur l'un des deux boutons la vidéo correspondante est affichée

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                handleVideoSelection(0)
            } else if (event.key === 'ArrowRight') {
                handleVideoSelection(1)
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [currentIndex])

    return visibility ? (
        <_Component className="popup-overlay-best" tag="div">
            <_Builtin.Block
                className="popup-box-best margin-medium rounded-8 overflow-scroll-navbar"
                tag="div"
            >
                <_Builtin.Block
                    className="popup-top-best background-color-blue200 padding-xsmall"
                    tag="div"
                    {...rpClosePopUp}
                >
                    <_Builtin.Block
                        className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
                        tag="div"
                    >
                        {''}
                    </_Builtin.Block>
                    <_Builtin.Heading className="text-align-center" tag="h2">
                        {'Diagen Tutorial'}
                    </_Builtin.Heading>
                    <_Builtin.Block
                        className="close-popup"
                        tag="div"
                        {...rpClosePopUp}
                    >
                        {'\ue91e'}
                    </_Builtin.Block>
                </_Builtin.Block>

                {/* Body */}
                <_Builtin.Block
                    className="popup-body-best padding padding-medium background-blue-150 z-index-1"
                    tag="div"
                >
                    {/* Boutons de sélection */}
                    <_Builtin.Block
                        className="flex-center margin-vertical margin-small gap-4px grid-2col"
                        tag="div"
                    >
                        <button
                            className="button is-secondary"
                            onClick={() => handleVideoSelection(0)}
                        >
                            Classic Dialog Generation
                        </button>
                        <button
                            className="button is-secondary"
                            onClick={() => handleVideoSelection(1)}
                        >
                            Upload Diagen Files
                        </button>
                    </_Builtin.Block>
                    <_Builtin.Paragraph className="bold padding-vertical padding-tiny">
                        {videos[currentIndex].title}
                    </_Builtin.Paragraph>

                    <_Builtin.Video
                        options={{
                            height: '450',
                            width: '800',
                            title: videos[currentIndex].title,
                            url: videos[currentIndex].url,
                        }}
                    />

                    {/* Bouton Annuler */}
                    <_Builtin.Block
                        className="button-group margin-top margin-xxsmall"
                        tag="div"
                        {...rpButtonCancel}
                    >
                        <_Builtin.Link
                            className="button is-large is-transparent"
                            button={false}
                            block="inline"
                            options={{
                                href: '#',
                            }}
                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonText buttonText="Cancel" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    ) : null
}

import { PopupNotification, SectionClosed, SectionCopyNpc } from 'devlink';
import { Team } from 'entities/character/character.model';
import { Npc } from 'entities/character/character.types';
import { useCopyNpc } from 'entities/db/db.queries';
import { sessionStore } from 'entities/session/session.model';
import { DatasetContext } from 'pages/dataset';
import React, { useContext, useLayoutEffect, useState } from 'react';

type MultiSelectProps = {
    value: string;
    label: string;
};

type CopyNpcProps = {
    characters: Npc[];
    selectedTeam: Team;
};

export function CopyNpc({ characters, selectedTeam }: CopyNpcProps) {
    const { setIsModalLockedOpen } = useContext(DatasetContext);
    const [teamOptions, setTeamOptions] = useState<MultiSelectProps[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<any[]>([]);
    const [selectedCharacterOptions, setSelectedCharacterOptions] = useState<MultiSelectProps[]>([]);
    const [copyCharacterSectionOpen, setCopyCharacterSectionOpen] = useState(false);
    const [popupElements, setPopupElements] = useState({ header: '', body: '', icon: '' });
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const { mutate: copyNpc, isPending: copyNpcPending, isError: isErrorCopy, isSuccess: isSuccessCopy, error: errorCopy, data: dataCopy } = useCopyNpc();

    useLayoutEffect(() => {
        if (isErrorCopy) {
            openPopup('\ue91e', 'Error', JSON.parse(errorCopy?.response ?? '').detail || 'An error occurred while copying the character. Please try again later.');
        }
    }, [isErrorCopy]);

    useLayoutEffect(() => {
        if (dataCopy?.message === 'Character already exists in the target team') {
            openPopup('\ue91e', 'Error', 'The character already exists in the target team.');
        }
    }, [dataCopy]);

    useLayoutEffect(() => {
        if (isSuccessCopy && !copyNpcPending) {
            openPopup('\ue917', 'Success', 'The character(s) has been successfully copied.');
        }
    }, [isSuccessCopy, copyNpcPending]);

    useLayoutEffect(() => {
        const currentState = sessionStore.getState();
        const filteredTeams = currentState.teams?.filter((team) => team.name !== selectedTeam?.name) || [];
        setTeamOptions(filteredTeams.map((team, index) => ({ label: team.name, value: index.toString() })));
        setSelectedCharacterOptions([]);
        setSelectedTeams([]);
    }, [selectedTeam]);

    const runCopyNpc = (characters: MultiSelectProps[], teams: MultiSelectProps[]) => {
        teams.forEach((team) => {
            characters.forEach((char) => {
                while (copyNpcPending) {
                    setTimeout(() => {}, 1000);
                }
                copyNpc({ team: selectedTeam?.name || '', target_team: team.label, npc: char.label });
            });
        });
    };

    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({ header: title, body: message, icon: icon });
        setIsModalPopupOpen(true);
    };

    if (teamOptions.length === 0) {
        return null;
    }

    return (
        <div onClick={() => { if (selectedTeam?.can_manage_datasets) return; setIsModalLockedOpen?.(true); }}>
            {!copyCharacterSectionOpen ? (
                <SectionClosed
                    rpButtonOpen={{ onClick: (e: Event) => { e.preventDefault(); setCopyCharacterSectionOpen(true); } }}
                    rpIcon={'\ue902'}
                    title={'Copy Character Section'}
                    subtitle={'Copy your characters to one of your other teams.'}
                />
            ) : (
                <SectionCopyNpc
                    disabled={!selectedTeam?.can_manage_datasets || copyNpcPending}
                    rpNpcDropdown={{
                        placeholder: 'Choose your npcs...',
                        isDisabled: !selectedTeam?.can_manage_datasets || copyNpcPending,
                        value: selectedCharacterOptions,
                        options: characters.filter((char) => char.name !== 'New Character').map((char) => ({ value: char.id.toString(), label: char.name })),
                        onChange: (selectedOptions: any) => { setSelectedCharacterOptions(selectedOptions); },
                    }}
                    rpTargetTeamDropdown={{
                        placeholder: 'Choose your team',
                        isDisabled: !selectedTeam?.can_manage_datasets || copyNpcPending,
                        value: selectedTeams,
                        options: teamOptions,
                        onChange: (selectedOptions: any) => { setSelectedTeams(selectedOptions); },
                    }}
                    rpCopyCharacters={{
                        onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            if (!selectedTeam?.can_manage_datasets) return;
                            runCopyNpc(selectedCharacterOptions, selectedTeams);
                            setIsCopied(true);
                            setTimeout(() => setIsCopied(false), 2000);
                        },
                    }}
                    rpCloseSection={{ onClick: (e: React.MouseEvent<HTMLButtonElement>) => { e.preventDefault(); setCopyCharacterSectionOpen(false); } }}
                />
            )}
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{ onClick: (e: React.MouseEvent) => { e.preventDefault(); setIsModalPopupOpen?.(false); setIsCopied(false); } }}
                rpInfoText={{ header: popupElements.header, body: popupElements.body, footer: '' }}
                rpIcon={popupElements.icon}
            />
        </div>
    );
}
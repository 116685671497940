import { Team } from 'entities/character/character.model'
import { Npc } from 'entities/character/character.types'
import { CopyNpc, MyCharacters } from './components'

type DatasetGenerationContainerProps = {
    characters: Npc[]
    selectedTeam: Team
    audios: any[]
    images: any[]
    onBackgroundChange?: (state: string) => void
}


export function SectionDatasetContainer({
    characters,
    selectedTeam,
    images,
    audios,
}: DatasetGenerationContainerProps) {
    return (
        <>
            <MyCharacters
                characters={characters}
                selectedTeam={selectedTeam}
                images={images}
                audios={audios}
            />
            <CopyNpc characters={characters} selectedTeam={selectedTeam} />
        </>
    )
}
import { createContext, useState, ReactNode, Dispatch,SetStateAction  } from 'react';
import { AdminModel } from 'entities/admin';

// Default values for the context
const defaultClickCounts = {
  unrealGeppetto: 0,
  unityGeppetto: 0,
  videoGeppettoUnreal: 0,
  videoGeppettoUnity: 0,
};

const defaultUserAccessRights: AdminModel.User = {
  id: undefined,
  name: '',
  email: '',
  is_owner: false,
  is_admin: false,
  can_manage_models: false,
  can_manage_npcs: false,
  can_manage_datasets: false,
  can_download_gepetto: false,
  can_download_ariel: false,
  can_download_diagen: false,
  date_joined: '',
  valid_from: '',
  valid_until: '',
};

type ClickCountsType = typeof defaultClickCounts;

type GeppettoContextType = {
  setUserAccessRights?: (value: AdminModel.User) => void;
  userAccessRights: AdminModel.User;
  isModalTutorialOpen?: boolean;
  setIsModalTutorialOpen?: (value: boolean) => void;
  isTutorialTriggered?: boolean;
  setIsTutorialTriggered?: (value: boolean) => void;
};

export const GeppettoContext = createContext<GeppettoContextType>({

  isModalTutorialOpen: false,
  userAccessRights: defaultUserAccessRights,
});

export const GeppettoProvider = ({ children }: { children: ReactNode }) => {
  // States with default values
  const [isModalTutorialOpen, setIsModalTutorialOpen] = useState(false);
  const [isTutorialTriggered, setIsTutorialTriggered] = useState(false);
  const [userAccessRights, setUserAccessRights] = useState<AdminModel.User>(defaultUserAccessRights);

  return (
    <GeppettoContext.Provider
      value={{
        isModalTutorialOpen,
        setIsModalTutorialOpen,
        userAccessRights,
        setUserAccessRights,
        isTutorialTriggered,
        setIsTutorialTriggered,
      }}
    >
      {children}
    </GeppettoContext.Provider>
  );
};
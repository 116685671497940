import React, { useContext, useLayoutEffect, useState } from 'react';
import { characterStore } from 'entities/character/character.model';
import { AnimationVideo, DemosGames } from 'devlink';

export function SectionDemosContainer() {
  return (
    <>
      <DemosGamesContainer />
    </>
  );
}

export function DemosGamesContainer() {
  const [accessRights, setAccessRights] = useState({
    can_download_ariel: 0,
    can_download_diagen: 0,
    can_download_gepetto: 0,
  });

  useLayoutEffect(() => {
    const setAccessRightsFromStore = (state: any) => {
      setAccessRights({
        can_download_ariel: state.currentTeam?.can_download_ariel || 0,
        can_download_diagen: state.currentTeam?.can_download_diagen || 0,
        can_download_gepetto: state.currentTeam?.can_download_gepetto || 0,
      });
    };

    const currentState = characterStore.getState();
    setAccessRightsFromStore(currentState);

    const unsubscribe = characterStore.subscribe((state) => {
      if (state.currentTeam) {
        setAccessRightsFromStore(state);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <DemosGames
        rpEvilLordDownload={{
          className: 'button is-primary',
          label: 'Download Evil Lord',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/EvilLord.zip', '_blank');
          },
        }}
        rpPoltergeistDownload={{
          className: 'button is-primary',
          label: 'Download Poltergeist',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/Poltergeist.zip', '_blank');
          }
        }}
        rpFlowerDownload={{
          className: 'button is-primary',
          label: 'Download Flower Island',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/FlowerIsland.zip', '_blank');
          },
        }}
        rpUserAccessRights={accessRights}
      />
    </>
  );
}


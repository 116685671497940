import { logsModel } from 'entities/loganalysis';
import { createContext, useState, useContext } from 'react'
import { StoreApi, createStore } from 'zustand'
import { DevtoolsOptions, devtools } from 'zustand/middleware'


const initialLogsState: logsModel.State = {
    file: new File([''], ''),
    file_name: '',
    chunk_max_tokens: 0,
    top_k: 0,
    llama_road_params: {
        n_gpu_layers: 0,
        n_ctx: 0,
        n_batch: 0,
        use_mmap: false,
        main_gpu: 0,
        verbose: false,
    },
    llama_generation_params: {
        max_tokens: 0,
        temperature: 0,
    },
    logsLines: [],
    logsUploadsPDF: [],
}

const logsStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'LogsPage LogsStore',
}



export const logsStore = createStore<logsModel.LogsState>()(
    devtools(
        logsModel.createLogsSlice(initialLogsState),
        logsStoreDevtoolsOptions
    )
)

type LogsContextType = {
    logsStore: StoreApi<logsModel.State>
    isModalBestPracticeOpen?: boolean
    setIsModalBestPracticeOpen?: (value: boolean) => void
    isModalTeaserOpen?: boolean
    setIsModalTeaserOpen?: (value: boolean) => void
    isTeaserTriggered?: boolean
    setIsTeaserTriggered?: (value: boolean) => void
    isBestPracticeTriggered?: boolean
    setIsBestPracticeTriggered?: (value: boolean) => void
}

export const LogsContext = createContext<LogsContextType>({
    logsStore,
    isModalBestPracticeOpen: false,
    isModalTeaserOpen: false,
})


export const LogsProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalBestPracticeOpen, setIsModalBestPracticeOpen] = useState(false)
    const [isModalTeaserOpen, setIsModalTeaserOpen] = useState(false)
    const [isTeaserTriggered, setIsTeaserTriggered] = useState(false)
    const [isBestPracticeTriggered, setIsBestPracticeTriggered] = useState(false)

    return (
        <LogsContext.Provider
            value={{
                logsStore,
                isModalBestPracticeOpen: isModalBestPracticeOpen,
                setIsModalBestPracticeOpen: setIsModalBestPracticeOpen,
                isBestPracticeTriggered: isBestPracticeTriggered,
                setIsBestPracticeTriggered: setIsBestPracticeTriggered,
                isModalTeaserOpen: isModalTeaserOpen,
                setIsModalTeaserOpen: setIsModalTeaserOpen,
                isTeaserTriggered: isTeaserTriggered,
                setIsTeaserTriggered: setIsTeaserTriggered,
            }}
        >
            {children}
        </LogsContext.Provider>
    )
}

export const useLogsContext = () => useContext(LogsContext);

import { LoaderGenerating, PageHeaderVoice } from 'devlink'
import { characterQueries } from 'entities/character'
import { characterStore } from 'entities/character/character.model'
import { sessionQueries } from 'entities/session'
import { VoiceContext } from 'pages/voice/voice-page.lib'
import { useContext, useLayoutEffect, useState } from 'react'
import { sessionStore } from '../../entities/session/session.model'

type TeamOption = {
    t: string
    v: string
}

export function PageHeaderVoiceContainer() {
    const { voiceStore, setIsModalRestartOpen, setIsModalExportOpen, setIsModalBestPracticeOpen } =
        useContext(VoiceContext)
    const [teamOptions, setTeamOptions] = useState<TeamOption[]>([])
    const [currentTeam, setCurrentTeam] = useState<
        TeamOption | null | undefined
    >(null)
    const [showCopyPopup, setShowCopyPopup] = useState(false)
    const [isDropdownProcessing, setIsDropdownProcessing] = useState(false)
    const [isFormFilled, setIsFormFilled] = useState(false)

    useLayoutEffect(() => {
        voiceStore.subscribe((state) => {
            setIsFormFilled(state.selectedCharacter !== undefined)
        })
    }, [voiceStore])
    
    useLayoutEffect(() => {
        const currentState = sessionStore.getState()
        setTeamOptions(
            currentState.teams?.map((team, index) => ({
                t: team.name,
                v: index.toString(),
            })) || []
        )
    }, [])

    useLayoutEffect(() => {
        if (!teamOptions || teamOptions.length === 0) return
        const currentState = characterStore.getState()
        setCurrentTeam(
            teamOptions.find((team) => team.t === currentState.currentTeam?.name) ||
                null
        )
    }, [teamOptions])

    useLayoutEffect(() => {
        const currentState = characterStore.getState()
        if (currentTeam && currentState.currentTeam?.name !== currentTeam.t) {
            getKeys()
            getNpcs()
            getImages()
            getAudios()
            return
        }
        if (!currentState.npcs){
            getNpcs()
        }
        if (!currentState.images){
            getImages()
        }
        if (!currentState.audios){
            getAudios()
        }
    }, [currentTeam])
    

    const { mutate: getKeys } = sessionQueries.useGetKeysMutation(false)
    const { mutate: getNpcs } = characterQueries.useNpcsService()
    const { mutate: getImages } = characterQueries.useImageListService()
    const { mutate: getAudios } = characterQueries.useAudioListService()
    
    useLayoutEffect(() => {
        const currentState = sessionStore.getState()
        setTeamOptions(
            currentState.teams?.map((team, index) => ({
                t: team.name,
                v: index.toString(),
            })) || []
        )
    }, [])

    useLayoutEffect(() => {
        if (!teamOptions || teamOptions.length === 0) return
        const currentState = characterStore.getState()
        setCurrentTeam(
            teamOptions.find((team) => team.t === currentState.currentTeam?.name) ||
                null
        )
    }, [teamOptions])

    return (
        <>
            <PageHeaderVoice
                rpRestartButton={{
                    className: `button ${isFormFilled ? 'is-primary' : 'is-disabled'}`,
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isFormFilled && setIsModalRestartOpen?.(true)
                    },
                }}
                rpButtonAPIDocumentation={{
                    className: `button is-icon`,
                    onClick: (e: Event) => {
                        e.preventDefault()
                        window.open('https://documenter.getpostman.com/view/15655549/2sAYdfrX6M', '_blank')
                    },
                }}
                rpExportBtn={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        setIsModalExportOpen?.(true)
                    },
                }}
                rpBestButton={{
                    className: `button is-secondary`,
                    onClick: (e: Event) => {
                        e.preventDefault()
                        setIsModalBestPracticeOpen?.(true)
                    },
                }}
                rpTeamsDropdown={{
                    value: currentTeam?.v || '',
                    name: currentTeam?.t || '',
                    options: teamOptions,
                    onChange: (event: any) => {
                        const team = teamOptions.find(
                            (team) => team.v === event.target.value
                        )
                        getKeys()
                        getNpcs()
                        getImages()
                        setCurrentTeam(team)
                        setIsDropdownProcessing(true) 
                        setTimeout(() => setIsDropdownProcessing(false), 2000) 
                        characterStore
                            .getState()
                            .updateCurrentTeam(
                                sessionStore.getState().teams?.find(
                                    (t) => t.name === team?.t
                                ) || ''
                            )
                    },
                }}
                rpCopyKeys={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        const currentState = sessionStore.getState()
                        const keys = currentState.keys
    
                        if (!keys) return
                        type KeyObject = {
                            [key: string]: string
                        }
    
                        const transformedKeys: KeyObject = Object.entries(
                            keys
                        ).reduce((acc: KeyObject, [key, value]) => {
                            acc[key] = value.key
                            return acc
                        }, {})
    
                        // copy to clipboard
                        navigator.clipboard.writeText(
                            JSON.stringify(transformedKeys, null, 2)
                        )
    
                        // Show popup
                        setShowCopyPopup(true)
                        setTimeout(() => setShowCopyPopup(false), 1000) // Hide after 1 second
                    },
                }}
                slotGenerationProcessing={
                    isDropdownProcessing && <LoaderGenerating />
                }
                showCopyPopup={showCopyPopup}
                showDropdown={teamOptions.length > 1}
            />
        </>
    )
}

import * as _Builtin from '../_Builtin'
import { AudioUpload } from './AudioUpload'
// const _interactionsData = ''

export function AudioItem({
    as: _Component = _Builtin.Block,
    key,
    index,
    voice,
    setVoiceFiles,
    disabled
}) {
    // _interactions.useInteractions(_interactionsData)
    const onClickDeleteVoice = (index) => {
        setVoiceFiles((prev) => {
            const newFiles = [...prev]
            newFiles[index].file = null
            newFiles[index].fileInfo = ''
            newFiles[index].deleted = true
            return newFiles
        })
    }

    const onDropVoice = async (index, e) => {
        const target = e
        if (target.dataTransfer) {
            const file = target.dataTransfer.files[0]
            const fileExtension = file.name.split('.').pop()

            setVoiceFiles((prev) => {
                const newFiles = [...prev]
                newFiles[index].file = file
                newFiles[index].fileInfo = ''
                return newFiles
            })
        }
    }
    
    const onClickVoice = async (index, e) => {
        if (e.target.id !== `PLAY-${index}`) {
            const input = document.createElement('input')
            input.type = 'file'
            input.accept = '.mp3,.wav'
            input.onchange = (e) => {
                const target = e.target
                if (target.files) {
                    const file = target.files[0]
                    if (file.size > 5 * 1024 * 1024) {
                        // set audio info error index
                        setVoiceFiles((prev) => {
                            const newFiles = [...prev]
                            newFiles[index].fileInfo =
                            'Audio file is too big - max 5MB'
                            return newFiles
                        })
                        return
                    }
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        // Audio data is now loaded but we'll use the file object directly
                    }
                    
                    // Update the voice files with the file object directly
                    setVoiceFiles((prev) => {
                        const newFiles = [...prev]
                        newFiles[index].file = file
                        newFiles[index].fileInfo = ''
                        return newFiles
                    })
                    
                    reader.readAsDataURL(file)
                }
            }
            input.click()
        }
    }

    
    return (
        <_Component tag="div" item={`VOICE-${index}`}>
            {
                !voice.deleted && (
                    <_Builtin.Block
                        className="margin-bottom margin-small"
                        tag="div"
                        key={index}
                    >
                        <_Builtin.Block className="fs-styleguide_section-2" tag="div">
                            <_Builtin.Block className="light-border grow" tag="div">
                                <_Builtin.FormTextInput
                                    className="input background-blue-150 min-h-3-4rem width-8rem"
                                    name="EMOTIONS"
                                    data-name="EMOTIONS"
                                    id={`EMOTIONS-${index}`}
                                    disabled={disabled || voice.audioUrl !== null || voice.updated === true}
                                    required={false}
                                    value={voice.emotion}
                                    placeholder="Emotion..."
                                    onChange={(e) => {
                                        const enteredEmotions = e.target.value
                                        setVoiceFiles((prevVoices) => {
                                            const newVoices = [...prevVoices]
                                            newVoices[index] = {
                                                ...newVoices[index],
                                                emotion: e.target.value,
                                            }
                                            return newVoices
                                        })
                                    }}
                                />
                            </_Builtin.Block>
                            <AudioUpload
                                index={index}
                                voice={voice}
                                setVoiceFiles={setVoiceFiles}
                                onClickVoice={onClickVoice}
                                onDropVoice={onDropVoice}
                                onClickDeleteVoice={onClickDeleteVoice}
                                disabled={disabled}
                            />
                            <_Builtin.Block className="button-group" tag="div">
                                <_Builtin.Block
                                    tag="div"
                                    onClick={(e) => onClickVoice(index, e)}
                                    onDrop={(e) => onDropVoice(index, e)}
                                >
                                    <_Builtin.HtmlEmbed
                                        className="button is-large is-secondary icon_ximm-big margin-right margin-tiny cursor-pointer"
                                        value="&#xe91b;"
                                    />
                                </_Builtin.Block>
                                <_Builtin.Link
                                    className="button.is-large"
                                    button={false}
                                    block="inline"
                                    options={{
                                        href: '#',
                                    }}
                                    onClick={() => onClickDeleteVoice(index)}
                                >
                                    <_Builtin.HtmlEmbed
                                        className="button is-red icon_ximm margin-right margin-tiny cursor-pointer"
                                        value="&#xe919;"
                                        id={`DELETE-${index}`}
                                    />
                                </_Builtin.Link>
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                )
            }
        </_Component>
    )
}

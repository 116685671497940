import { SlugPageParams, UsernamePageParams } from './react-router.types'

export const pathKeys = {
    root: '',
    login() {
        return pathKeys.root.concat('/login')
    },
    register() {
        return pathKeys.root.concat('/register')
    },
    settings() {
        return pathKeys.root.concat('/settings')
    },
    home() {
        return pathKeys.root.concat('/')
    },
    logsanalysis() {
        return pathKeys.root.concat('/logsanalysis')
    },
    corporatehelper() {
        return pathKeys.root.concat('/corporatehelper')
    },
    diagen() {
        return pathKeys.root.concat('/diagen')
    },
    geppetto() {
        return pathKeys.root.concat('/geppetto')
    },
    demos() {
        return pathKeys.root.concat('/demos')
    },
    ariel() {
        return pathKeys.root.concat('/ariel')
    },
    roll() {
        return pathKeys.root.concat('/roll')
    },
    gamehelper() {
        return pathKeys.root.concat('/gamehelper')
    },
    dataset() {
        return pathKeys.root.concat('/dataset')
    },
    plugins() {
        return pathKeys.root.concat('/plug-ins')
    },
    datausage() {
        return pathKeys.root.concat('/data-usage')
    },
    contact() {
        return pathKeys.root.concat('/contact')
    },
    updates() {
        return pathKeys.root.concat('/updates')
    },
    admin() {
        return pathKeys.root.concat('/admin')
    },
    cgv() {
        return pathKeys.root.concat('/cgv')
    },
    page404() {
        return pathKeys.root.concat('/404')
    },
    article: {
        root() {
            return pathKeys.root.concat('/article')
        },
        bySlug({ slug }: SlugPageParams) {
            return pathKeys.article.root().concat(slug, '/')
        },
    },
    profile: {
        root() {
            return pathKeys.root.concat('/profile')
        },
        byUsername({ username }: UsernamePageParams) {
            return pathKeys.profile.root().concat(username, '/')
        },
        byUsernameFavorites({ username }: UsernamePageParams) {
            return pathKeys.profile
                .byUsername({ username })
                .concat('/favorites')
        },
    },
    editor: {
        root() {
            return pathKeys.root.concat('/editor')
        },
        bySlug({ slug }: SlugPageParams) {
            return pathKeys.editor.root().concat(slug, '/')
        },
    },
}

import * as _Builtin from '../_Builtin'
import { AudioItem } from './AudioItem'

// const _interactionsData = ''

export function AudioList({
    as: _Component = _Builtin.Block,
    voiceFiles,
    setVoiceFiles,
    disabled,
}) {
    // _interactions.useInteractions(_interactionsData)
    return (
        <_Component>
            {voiceFiles.map((voice, index) => (
                <AudioItem 
                    key={`VOICE-${index}`}
                    index={index}
                    voice={voice}
                    setVoiceFiles={setVoiceFiles}
                    disabled={disabled}
                />
            ))}
        </_Component>
    )
}
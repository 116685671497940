import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'

// const _interactionsData = ''

export function AddRemoveButton({
    as: _Component = _Builtin.Block,
    voiceFiles,
    setVoiceFiles,
}) {
    // _interactions.useInteractions(_interactionsData)
    const handleAddVoice = () => {
        const newVoiceFiles = [...voiceFiles]
        newVoiceFiles.push({
            file: null,
            audioUrl: null,
            emotion: '',
            fileInfo: '',
            updated: false,
            deleted: false,
            playing: false,
            preset: false,
        })
        setVoiceFiles(newVoiceFiles)
    }

    const handleRemoveVoice = (index) => {
        let updatedVoiceFiles = [...voiceFiles]
        for (let i = index; i >= 0; i--) {
            if (!updatedVoiceFiles[i].deleted) {
            updatedVoiceFiles[i] = { ...updatedVoiceFiles[i], deleted: true }
            break
            }
        }
        setVoiceFiles(updatedVoiceFiles)
    }

    return (

        <_Component>
            {voiceFiles && voiceFiles.filter((voice) => !voice.deleted).length < 5 && (
                <>
                    <_Builtin.Block
                        className="separator horizontal grow"
                        tag="div"
                    />
                    <_Builtin.Link
                        className="button is-transparent text-color-blue40"
                        button={false}
                        block="inline"
                        options={{
                            href: '#',
                        }}
                        onClick={() => {
                            handleAddVoice()
                        }}
                    >
                        <_Builtin.Block className="slot-button" tag="div">
                            <ButtonIcon buttonIconText="" />
                            <ButtonText buttonText="Add" />
                        </_Builtin.Block>
                    </_Builtin.Link>
                </>
            )}
            {voiceFiles && voiceFiles.length > 1 && (
                <>
                    <_Builtin.Link
                        className="button is-transparent text-color-blue40"
                        button={false}
                        block="inline"
                        options={{
                            href: '#',
                        }}
                        onClick={() => handleRemoveVoice(voiceFiles.length - 1)}
                    >
                        <_Builtin.Block className="slot-button" tag="div">
                            <ButtonIcon buttonIconText="&#xe90e;" />
                            <ButtonText buttonText="Remove" />
                        </_Builtin.Block>
                    </_Builtin.Link>
                </>
            )}
        </_Component>
    )
}

import { SectionDemos } from 'widgets/demos'
import { DemosProvider } from './demos-page.model'
import { HeaderLayout } from 'devlink'
import { PageHeaderDemos, PopupDemosTutorial } from 'widgets/demos'

function DemosPageContent() {

    return (
        <>
            <HeaderLayout
                slotHeader={<PageHeaderDemos/>}
                slotHeaderLayout={<SectionDemos />}
            />
                        <PopupDemosTutorial/>

        </>
    )
}

export function DemosPage() {
    return (
        <DemosProvider>
            <DemosPageContent />
        </DemosProvider>
    )
}
import {
    RdResultsCard,
    RdSelectCard,
    RollGenerationProcessingStateGenerating,
    RollGenerationProcessingStateReady,
    SectionRollGeneration,
} from 'devlink'
import { characterTypes } from 'entities/character'
import { presetTypes } from 'entities/preset'
import { rollRecordTypes } from 'entities/roll-record'
import { useCreateRollRecordMutation } from 'entities/roll-record/roll-record.queries'
import { generateLine } from 'features/roll-line/generate/roll-line'
import { RollContext, rollStore } from 'pages/roll/roll-page.model'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Loader } from 'shared/ui/loader'
import { SliderRange } from 'shared/ui/slider'
import { characterStore } from '../../entities/character/character.model'

const presets = [
    {
        id: 0,
        name: 'Critical Fail -- Critical Success',
        prompts: {
            Critical_Fail:
                ' You must answer in the worst possible way and refuse the request with insults or threats.',
            Fail: ' You must respond negatively to the request.',
            Success: ' You must respond slightly positively to the request.',
            Critical_Success:
                ' You must respond as favourably as possible to your best friend.',
        },
    },
    {
        id: 1,
        name: 'Stranger -- Confident',
        prompts: {
            Stranger: " You've just met this stranger for the first time.",
            Acquittance:
                " You vaguely know this person, and you've met a few times. ",
            Friend: " You know this person, it's a distant friend.",
            Confident:
                " You know very well this person, it's a very close friend and confident.",
        },
    },
    {
        id: 2,
        name: 'Angry -- Happy',
        prompts: {
            Angry: " You're angry and very upset.",
            Sad: " You're sad and confused.",
            Neutral: " You're neutral and even-tempered.",
            Happy: " You're feeling good,  very happy and enthusiastic.",
        },
    },
]

type RollGenerationContainerProps = {
    isCharacterLoading?: boolean
    characters: characterTypes.Npcs
}
export function RollGenerationContainer({
    isCharacterLoading,
    characters,
}: RollGenerationContainerProps) {
    useLayoutEffect(() => {
        if (rollStore.getState()) {
            rollStore.getState().load()
        }
    }, [])

    useLayoutEffect(() => {
        return () => {
            if (rollStore.getState()) {
                rollStore.getState().save()
            }
        }
    }, [])

    if (isCharacterLoading) {
        return (
            <SectionRollGeneration
                slotCharacterRollCard={
                    <>
                        {
                            <div
                                style={{
                                    background: '#1E2338',
                                    height: '493px',
                                    width: '100%',
                                    marginLeft: '4%',
                                    borderRadius: '8px',
                                }}
                            >
                                <Loader size="parent" />
                            </div>
                        }
                    </>
                }
                slotRollCards={
                    <>
                        {
                            <div
                                style={{
                                    background: '#1E2338',
                                    height: '493px',
                                    width: '100%',
                                    marginLeft: '4%',
                                    borderRadius: '8px',
                                }}
                            >
                                <Loader size="parent" />
                            </div>
                        }
                    </>
                }
            />
        )
    }
    return (
        <SectionRollGeneration
            slotCharacterRollCard={
                <>
                    <SectionRollNpcSelectCard
                        characters={characters}
                        presets={presets}
                        initialState={{ characterIndex: 0 }}
                    />
                    {/* {characters?.length > 0 && (
                        <SectionRollNpcSelectCard
                            characters={characters}
                            presets={presets}
                            initialState={{ characterIndex: 0 }}
                        />
                    )} */}
                </>
            }
            slotRollCards={
                <>
                    <SectionRollGenerationCards roll_presets={presets} />
                </>
            }
        />
    )
}

type RollNpcSelectCardContainerProps = {
    characters: characterTypes.Npcs
    presets: presetTypes.Preset[]
    initialState?: {
        characterIndex: number
    }
}

function wait(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, milliseconds)
    })
}

function SectionRollNpcSelectCard(props: RollNpcSelectCardContainerProps) {
    const { characters, presets, initialState } = props
    const { rollStore } = useContext(RollContext)
    const [selectedCharacter, setSelectedCharacter] =
        useState<characterTypes.Npc | null>(null)
    const [selectedLanguage, setSelectedLanguage] = useState('en')
    const [selectedPreset, setSelectedPreset] =
        useState<presetTypes.Preset | null>(presets[0])

    const [toxicity, setToxicity] = useState(0.5)
    const [temperature, setTemperature] = useState(1)
    const [maxToken, setMaxToken] = useState(50)
    const defaultValue = {
        t: 'Choose your NPC',
        v: '',
    }
    const [characterImage, setCharacterImage] = useState<string | null>(null)
    const [selectedTeam, setSelectedTeam] = useState<string | null | undefined>(
        characterStore.getState().currentTeam?.name || null
    )
    const [rollQuestionText, setRollQuestionText] = useState('')
    const [rollRecordText, setRollRecordText] = useState('')
    const [isGenerateButtonEnabled, setIsGenerateButtonEnabled] =
        useState(false)
    const [isGenerateButtonClicked, setIsGenerateButtonClicked] =
        useState(false)
    const [cardsGenerated, setCardsGenerated] = useState(false)
    const languageOptions = [
        {
            t: 'English',
            v: 'en',
        },
        {
            t: 'French',
            v: 'fr',
        },
        {
            t: 'Spanish',
            v: 'es',
        },
        {
            t: 'German',
            v: 'de',
        },
        {
            t: 'Italian',
            v: 'it',
        },
        {
            t: 'Portuguese',
            v: 'pt',
        },
    ]

    const {
        mutate: createRollRecord,
        isPending: isCreatingRollRecord,
        isSuccess: isRollRecordCreated,
    } = useCreateRollRecordMutation(rollStore)

    const characterOptions = [
        defaultValue,
        ...characters?.map((character) => ({
            t: character?.name,
            v: character?.id.toString(),
        })),
    ]

    const rollOptions = [
        ...(presets?.map((preset) => ({
            t: preset.name,
            v: preset.id.toString(),
        })) || []),
    ].sort((a, b) => a.t.localeCompare(b.t))

    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (selectedTeam && state.currentTeam?.name !== selectedTeam) {
                setCharacterImage(null)
                setSelectedTeam(state.currentTeam?.name)
                setSelectedCharacter(null)
                setCharacterImage(null)
                rollStore.getState().reset()
            }
        })
    }, [selectedTeam])

    useLayoutEffect(() => {
        setSelectedCharacter(rollStore.getState().selectedCharacter || null)
    })

    useLayoutEffect(() => {
        if (selectedCharacter && rollRecordText && rollQuestionText) {
            setIsGenerateButtonEnabled(true)
        } else {
            setIsGenerateButtonEnabled(false)
        }
    }, [selectedCharacter, rollRecordText, rollQuestionText])

    useLayoutEffect(() => {
        if (selectedCharacter) {
            const currentState = characterStore.getState().images
            const characterImage = currentState?.find(
                (image) => image.name === selectedCharacter.name
            )
            setCharacterImage(characterImage?.image || null)
        }
    }, [selectedCharacter])

    useEffect(() => {
        if (isGenerateButtonClicked) {
            const timer = setTimeout(() => {
                setCardsGenerated(true)
            }, 4000)

            return () => clearTimeout(timer)
        }
    }, [isGenerateButtonClicked])

    return (
        <>
            <RdSelectCard
                characterImage={
                    selectedCharacter && selectedCharacter.name === 'Player'
                        ? Math.random() < 0.5
                            ? '/images/you_female.png'
                            : '/images/you_male.png'
                        : characterImage || '/images/blank.png'
                }
                rpDropdownCharacter={{
                    label: 'Choose your NPC',
                    options: characterOptions.filter(option => option.t !== 'Player'),
                    value: selectedCharacter?.id.toString() || '',
                    onChange: (e: Event) => {
                    const target = e.target as HTMLSelectElement
                    const sortedCharacters = characters.sort((a, b) =>
                        a.name.localeCompare(b.name)
                    )
                    const selectedCharacter = sortedCharacters.find(
                        (character) =>
                        character.id.toString() === target.value
                    )
                    setSelectedCharacter(selectedCharacter || null)
                    rollStore.setState({
                        selectedCharacter: selectedCharacter,
                    })
                    },
                }}
                rpDropdownPreset={{
                    label: 'Choose your roll preset',
                    options: rollOptions,
                    value: selectedPreset?.id.toString() || '',
                    onChange: (e: Event) => {
                        const target = e.target as HTMLSelectElement
                        const selectedPreset = presets.find(
                            (preset) => preset.id.toString() === target.value
                        )
                        setSelectedPreset(selectedPreset || null)
                        rollStore.setState({
                            selectedPreset: selectedPreset,
                        })
                    },
                }}
                rpSliderRangeTemperature={
                    <SliderRange
                        value={((temperature - 0.1) / 0.9) * 100}
                        onChange={(value: number) => {
                            setTemperature((value / 100) * 0.9 + 0.1)
                        }}
                        min={0}
                        max={200}
                    />
                }
                rpSliderRangeToxicity={
                    <SliderRange
                        value={((toxicity - 0.1) / 0.9) * 100}
                        onChange={(value: number) => {
                            setToxicity(Math.round((value / 100) * 0.9 + 0.1))
                        }}
                        min={0}
                        max={100}
                    />
                }
                rpSliderRangeToken={
                    <SliderRange
                        value={Math.round((maxToken - 20) * 2)}
                        onChange={(value: number) => {
                            setMaxToken(Math.round(value / 2 + 20)) 
                        }}
                        min={0}
                        max={100}
                    />
                }
                rpButtonDelete={{
                    className: `button is-icon is-square ${rollRecordText === '' ? 'is-disabled' : ''}`,
                    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        setRollQuestionText('')
                        setRollRecordText('')
                        setSelectedCharacter(null)
                        setToxicity(0.5)
                        setTemperature(0.5)
                        setMaxToken(50)
                    },
                }}
                rpDropdownLanguage={{
                    label: 'Choose your language',
                    options: languageOptions,
                    value: selectedLanguage,
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                        const language = e.target.value
                        setSelectedLanguage(language)
                    },
                }}
                rpRollText={{
                    value: rollRecordText,
                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const updatedSentence = e.target.value
                        if (rollRecordText) {
                        }
                        setRollRecordText(updatedSentence)
                    },
                }}
                rpRollQuestion={{
                    value: rollQuestionText,
                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const updatedSentence = e.target.value
                        setRollQuestionText(updatedSentence)
                    },
                }}
                buttonVisibility={!isGenerateButtonClicked}
                rpButtonGenerate={{
                    className: `button is-primary ${isGenerateButtonEnabled ? '' : 'is-disabled'}`,
                    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        if (isGenerateButtonEnabled) {
                            rollStore.setState({
                                rollRecords: [],
                            })
                            if (selectedPreset?.prompts) {
                                Object.keys(selectedPreset.prompts).forEach(
                                    (key) => {
                                        const startState =
                                            rollRecordText +
                                            '. ' +
                                            (
                                                selectedPreset.prompts as {
                                                    [key: string]: any
                                                }
                                            )[key]
                                        const rollRecord = {
                                            temperature,
                                            npc_name:
                                                selectedCharacter?.name || '',
                                            maxToken,
                                            toxicity,
                                            language: selectedLanguage,
                                            startState: startState,
                                            question: rollQuestionText,
                                            character:
                                                selectedCharacter?.name || '',
                                            presets:
                                                selectedPreset.id.toString(),
                                            prompt_name: key,
                                        }
                                        createRollRecord({
                                            rollRecord,
                                            npcName:
                                                selectedCharacter?.name || '',
                                            preset: selectedPreset.id.toString(),
                                        })
                                    }
                                )
                                setIsGenerateButtonClicked(true)
                            }
                        }
                    },
                }}
                slotGenerationProcessing={
                    <>
                        {isGenerateButtonEnabled &&
                            isGenerateButtonClicked &&
                            !cardsGenerated && (
                                <RollGenerationProcessingStateGenerating />
                            )}
                        {isGenerateButtonEnabled &&
                            !isGenerateButtonClicked && (
                                <RollGenerationProcessingStateReady />
                            )}
                    </>
                }
            />
        </>
    )
}

type SectionRollGenerationCardsProps = {
    roll_presets: presetTypes.Preset[]
}

function SectionRollGenerationCards(props: SectionRollGenerationCardsProps) {
    const { roll_presets } = props
    const { rollStore } = useContext(RollContext)
    const [rollRecords, setRollRecords] = useState<
        rollRecordTypes.RollRecord[]
    >(rollStore.getState()?.rollRecords || [])
    const [rollQuestion, setRollQuestion] =
        useState<rollRecordTypes.RollQuestion>(
            rollStore.getState()?.rollQuestion || { sentence: '' }
        )
    const [selectedCharacter, setSelectedCharacter] = useState(
        rollStore.getState()?.selectedCharacter
    )
    const [isNpcCardFilled, setIsNpcCardFilled] = useState(
        selectedCharacter != null
    )

    useLayoutEffect(() => {
        rollStore.subscribe((state) => {
            setRollRecords(state.rollRecords || [])
            setRollQuestion(state.rollQuestion || { sentence: '' })
            setSelectedCharacter(state.selectedCharacter)
        })
    }, [rollStore])

    useLayoutEffect(() => {
        setIsNpcCardFilled(selectedCharacter != null)
    }, [selectedCharacter])

    return (
        <>
            {rollRecords.length !== 0 && (
                <>
                    {roll_presets &&
                        roll_presets.length > 0 &&
                        roll_presets.map((preset) =>
                            Object.keys(preset.prompts).map((key, index) => (
                                <RollGenerationCard
                                    key={index}
                                    index={index}
                                    rollRecord={
                                        rollRecords.find(
                                            (record) =>
                                                record.prompt_name === key &&
                                                record.presets ===
                                                    preset.id.toString()
                                        ) || {
                                            presets: '',
                                            npc_name: '',
                                            temperature: 0,
                                            toxicity: 0,
                                            maxToken: 0,
                                            language: '',
                                            startState: '',
                                            question: '',
                                            response: '',
                                            prompt_name: '',
                                        }
                                    }
                                    rollQuestion={rollQuestion}
                                    isNpcCardFilled={isNpcCardFilled}
                                />
                            ))
                        )}
                </>
            )}
        </>
    )
}

type RollGenerationCardProps = {
    index: number
    rollQuestion: { sentence: string }
    isNpcCardFilled: boolean
    rollRecord: {
        presets: string
        npc_name: string
        toxicity: number
        temperature: number
        maxToken: number
        language: string
        startState: string
        question: string
        response: string
        prompt_name: string
    }
}
function RollGenerationCard(props: RollGenerationCardProps) {
    const { index, rollRecord } = props
    const { rollStore } = useContext(RollContext)

    const {
        mutate: createRollRecord,
        isPending: isCreatingRollRecord,
        isSuccess: isRollRecordCreated,
        // data: rollRecordData,
        // reset: resetRollRecord,
    } = useCreateRollRecordMutation(rollStore)

    const [selectedPreset, setSelectedPreset] =
        useState<presetTypes.Preset | null>(presets[0])

    const [linesToGenerate, setLinesToGenerate] = useState(0)

    useLayoutEffect(() => {
        if (linesToGenerate > 0) {
            generateLine(createRollRecord, rollStore, {
                maxToken: rollRecord.maxToken,
                npc_name: rollRecord.npc_name,
                character: rollRecord.npc_name,
                temperature: rollRecord.temperature,
                language: rollRecord.language,
                presets: rollRecord.presets,
                toxicity: rollRecord.toxicity,
                startState: rollRecord.startState,
                question: rollRecord.question,
                prompt_name: rollRecord.prompt_name,
            })
        }
    }, [linesToGenerate])

    useLayoutEffect(() => {
        if (isRollRecordCreated) {
            setLinesToGenerate(linesToGenerate - 1)
        }
    }, [isRollRecordCreated])

    const [isRpRollRegenerateClicked, setIsRpRollRegenerateClicked] =
        useState(false)

    return (
        <>
            {selectedPreset && rollRecord.response && (
                <div key={selectedPreset.id}>
                    <RdResultsCard
                        radResultsCardTitle={rollRecord.prompt_name}
                        radResultsCardText={rollRecord.response}
                        rpButtonCopy={{
                            onClick: (e: Event) => {
                                navigator.clipboard.writeText(
                                    rollRecord.response
                                )
                            },
                            className: `button.is-icon.is-square margin-left margin-xxsmall`,
                        }}
                        rpRollRegenerate={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                generateLine(createRollRecord, rollStore, {
                                    maxToken: rollRecord.maxToken,
                                    temperature: rollRecord.temperature,
                                    language: rollRecord.language,
                                    presets: rollRecord.presets,
                                    toxicity: rollRecord.toxicity,
                                    character: rollRecord.npc_name,
                                    npc_name: rollRecord.npc_name,
                                    startState: rollRecord.startState,
                                    question: rollRecord.question,
                                    prompt_name: rollRecord.prompt_name,
                                })
                                setIsRpRollRegenerateClicked(true)
                            },
                        }}
                        slotRegenerationProcessing={
                            isRpRollRegenerateClicked &&
                            isCreatingRollRecord && (
                                <RollGenerationProcessingStateGenerating />
                            )
                        }
                    />
                </div>
            )}
        </>
    )
}

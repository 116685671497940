import React from 'react'
import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export function DemosGames({
    as: _Component = _Builtin.Block,
    rpUserAccessRights,
    rpFlowerDownload,
    rpPoltergeistDownload,
    rpEvilLordDownload,
}) {
    return (
        <_Component className="container-large padding padding-small" tag="div">
            <_Builtin.Block className="section-title-wrapper margin-bottom margin-xsmall" tag="div">
                <_Builtin.Block className="grid-3col">
                    {/* Flower Island */}
                                        <_Builtin.Block className="grid-3row demo demos-block" tag="div">
                                            <_Builtin.Block className="content-container padding-top padding-medium" tag="div">
                                                <_Builtin.Paragraph className="text-weight-semibold text-size-large flex-center">
                                                    {'Flower Island'}
                                                </_Builtin.Paragraph>
                                                <_Builtin.Paragraph className="text-color-light-grey justify-text italic">
                                                    {"You just landed on Flower Island, it seems quite a nice and quiet place, although... something seems off, doesn't it?"}
                                                </_Builtin.Paragraph>
                                                <_Builtin.Paragraph className="text-color-light-grey justify-text italic">
                                                    {'Point & click on NPCs to make them talk to you, and learn what happened here!'}
                                                </_Builtin.Paragraph>
                                            </_Builtin.Block>
                                            <_Builtin.Block className="padding-vertical padding-small">
                                                <_Builtin.Video
                                                    options={{
                                                        height: '152',
                                                        width: '270',
                                                        title: 'X&Immersion - Flower Island',
                                                        url: '//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FSZGEHh1zVYI%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DSZGEHh1zVYI&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FSZGEHh1zVYI%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube',
                                                    }}
                                                />
                                            </_Builtin.Block>
                                            <_Builtin.Block className="padding-vertical padding-medium justify-text grid-2row demo">
                                                <_Builtin.Block>
                                                    <_Builtin.Paragraph className="text-weight-semibold text-size-large margin-bottom margin-small">
                                                        {'Tech used:'}
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Paragraph className="text-weight-semibold">
                                                        <FontAwesomeIcon icon={faCheck} className="icon-check margin-right margin-tiny" />
                                                        {'Automatic Dialogue Generation:'}
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Paragraph className="text-color-light-grey margin-bottom margin-tiny">
                                                        {"Processed locally on the user's computer during inference."}
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Paragraph className="text-weight-semibold">
                                                        <FontAwesomeIcon icon={faCheck} className="icon-check margin-right margin-tiny" />
                                                        {'Requires GPU:'}
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Paragraph className="text-color-light-grey margin-bottom margin-tiny">
                                                        {'Needs an NVIDIA GPU with 4GB or more for optimal performance.'}
                                                    </_Builtin.Paragraph>
                                                </_Builtin.Block>
                                                <_Builtin.Block>
                                                    {rpUserAccessRights.can_download_gepetto === 1 ? (
                                                        <_Builtin.Block tag="div">
                                                            <_Builtin.Block className="grid-2col flex-center padding padding-large margin-top margin-large" tag="div">
                                                            <_Builtin.Paragraph>{'Application'}</_Builtin.Paragraph>
                                                                <_Builtin.Link className="button is-secondary" button={false} {...rpFlowerDownload}>
                                                                    <_Builtin.Block className="slot-button" tag="div">
                                                                        <ButtonIcon buttonIconText="" />
                                                                        <ButtonText buttonText="Download" />
                                                                    </_Builtin.Block>
                                                                </_Builtin.Link>
                                                            </_Builtin.Block>
                                                        </_Builtin.Block>
                                                    ) : (
                                                        <_Builtin.Block className="textposition margin-vertical margin-large" tag="div">
                                                            <_Builtin.Paragraph className="text-color-light-grey">
                                                                {'You want to get access to the demo?'}
                                                            </_Builtin.Paragraph>
                                                            <_Builtin.Paragraph className="text-color-light-grey">
                                                                {'Contact us at '}
                                                                <a href="mailto:bastien.s@xandimmersion.com">bastien.s@xandimmersion.com</a>
                                                                {' and someone from our team will answer you!'}
                                                            </_Builtin.Paragraph>
                                                        </_Builtin.Block>
                                                    )}
                                                </_Builtin.Block>
                                            </_Builtin.Block>
                                        </_Builtin.Block>

                                        {/* Evil Lord */}
                    <_Builtin.Block className="grid-3row demo demos-block" tag="div">
                        <_Builtin.Block className="content-container padding-top padding-medium" tag="div">
                            <_Builtin.Paragraph className="text-weight-semibold text-size-large flex-center">
                                {'Evil Lord'}
                            </_Builtin.Paragraph>
                            <_Builtin.Paragraph className="text-color-light-grey justify-text italic">
                                {'You step into the Imperial Palace as the negotiator of Anderian.'}
                            </_Builtin.Paragraph>
                            <_Builtin.Paragraph className="text-color-light-grey justify-text italic">
                                {'Your nation is at war with the Empire, and your armies are weakened. Facing Empress Abrogial, you must negotiate peace—or risk the destruction of your home.'}
                            </_Builtin.Paragraph>
                        </_Builtin.Block>
                        <_Builtin.Block className="padding-vertical padding-small">
                            <_Builtin.Video
                                options={{
                                    height: '152',
                                    width: '270',
                                    title: 'X&Immersion - Evil Lord',
                                    url: '//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FgBbGJNnKUv8%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DgBbGJNnKUv8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FgBbGJNnKUv8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube',
                                }}
                            />
                        </_Builtin.Block>
                        <_Builtin.Block className="padding-vertical padding-medium justify-text grid-2row demo">
                            <_Builtin.Block>
                                <_Builtin.Paragraph className="text-weight-semibold text-size-large margin-bottom margin-small">
                                    {'Tech used:'}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-weight-semibold">
                                    <FontAwesomeIcon icon={faCheck} className="icon-check margin-right margin-tiny" />
                                    {'Automatic Dialogue Generation:'}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-color-light-grey margin-bottom margin-tiny">
                                    {"Generated directly on the user's computer."}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-weight-semibold">
                                    <FontAwesomeIcon icon={faCheck} className="icon-check margin-right margin-tiny" />
                                    {'Voice and Facial Animation Generation:'}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-color-light-grey margin-bottom margin-tiny">
                                    {'Enhances realism and interaction.'}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-weight-semibold">
                                    <FontAwesomeIcon icon={faCheck} className="icon-check margin-right margin-tiny" />
                                    {'Requires GPU:'}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-color-light-grey margin-bottom margin-tiny">
                                    {'Needs an NVIDIA GPU with 4GB or more for optimal performance.'}
                                </_Builtin.Paragraph>
                            </_Builtin.Block>
                            <_Builtin.Block>
                                {rpUserAccessRights.can_download_gepetto === 1 ? (
                                    <_Builtin.Block tag="div">
                                        <_Builtin.Block className="grid-2col flex-center padding padding-large margin-top margin-large" tag="div">
                                            <_Builtin.Paragraph>{'Application'}</_Builtin.Paragraph>
                                            <_Builtin.Link className="button is-secondary" button={false} {...rpEvilLordDownload}>
                                                <_Builtin.Block className="slot-button" tag="div">
                                                    <ButtonIcon buttonIconText="" />
                                                    <ButtonText buttonText="Download" />
                                                </_Builtin.Block>
                                            </_Builtin.Link>
                                        </_Builtin.Block>
                                    </_Builtin.Block>
                                ) : (
                                    <_Builtin.Block className="textposition margin-vertical margin-large" tag="div">
                                        <_Builtin.Paragraph className="text-color-light-grey">
                                            {'You want to get access to the demo?'}
                                        </_Builtin.Paragraph>
                                        <_Builtin.Paragraph className="text-color-light-grey">
                                            {'Contact us at '}
                                            <a href="mailto:bastien.s@xandimmersion.com">bastien.s@xandimmersion.com</a>
                                            {' and someone from our team will answer you!'}
                                        </_Builtin.Paragraph>
                                    </_Builtin.Block>
                                )}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>

                    {/* Poltergeist */}
                    <_Builtin.Block className="grid-3row demo demos-block" tag="div">
                        <_Builtin.Block className="content-container padding-top padding-medium" tag="div">
                            <_Builtin.Paragraph className="text-weight-semibold text-size-large flex-center margin-bottom margin-small">
                                {'Poltergeist'}
                            </_Builtin.Paragraph>
                            <_Builtin.Paragraph className="text-color-light-grey justify-text italic">
                                {'You are a ghost, and you are haunting a house. You have to scare the visitors coming to sleep there, but be careful, they are not easy to scare!'}
                            </_Builtin.Paragraph>
                        </_Builtin.Block>
                        <_Builtin.Block className="padding-vertical padding-small">
                            <_Builtin.Video
                                options={{
                                    height: '152',
                                    width: '270',
                                    title: 'X&Immersion - POLTERGEIST',
                                    url: '//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FBXEvCinY4QE%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DBXEvCinY4QE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FBXEvCinY4QE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube',
                                }}
                            />
                        </_Builtin.Block>
                        <_Builtin.Block className="padding-vertical padding-medium justify-text grid-2row demo">
                            <_Builtin.Block>
                                <_Builtin.Paragraph className="text-weight-semibold text-size-large margin-bottom margin-small">
                                    {'Tech used:'}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-weight-semibold">
                                    <FontAwesomeIcon icon={faCheck} className="icon-check margin-right margin-tiny" />
                                    {'Automatic Dialogue Generation:'}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-color-light-grey margin-bottom margin-tiny">
                                    {"Processed locally on the user's computer during inference."}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-weight-semibold">
                                    <FontAwesomeIcon icon={faCheck} className="icon-check margin-right margin-tiny" />
                                    {'Voice and Facial Animation Generation:'}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-color-light-grey margin-bottom margin-tiny">
                                    {'Enhances realism and interaction.'}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-weight-semibold">
                                    <FontAwesomeIcon icon={faCheck} className="icon-check margin-right margin-tiny" />
                                    {'Requires GPU:'}
                                </_Builtin.Paragraph>
                                <_Builtin.Paragraph className="text-color-light-grey margin-bottom margin-tiny">
                                    {'Needs an NVIDIA GPU with 8GB or more for optimal performance.'}
                                </_Builtin.Paragraph>
                            </_Builtin.Block>
                            <_Builtin.Block>
                                {rpUserAccessRights.can_download_gepetto === 1 ? (
                                    <_Builtin.Block tag="div">
                                        <_Builtin.Block className="grid-2col flex-center padding padding-large margin-top margin-large" tag="div">
                                        <_Builtin.Paragraph>{'Application'}</_Builtin.Paragraph>
                                            <_Builtin.Link className="button is-primary" button={false} {...rpPoltergeistDownload}>
                                                <_Builtin.Block className="slot-button" tag="div">
                                                    <ButtonIcon buttonIconText="" />
                                                    <ButtonText buttonText="Download" />
                                                </_Builtin.Block>
                                            </_Builtin.Link>
                                        </_Builtin.Block>
                                    </_Builtin.Block>
                                ) : (
                                    <_Builtin.Block className="textposition margin-vertical margin-large" tag="div">
                                        <_Builtin.Paragraph className="text-color-light-grey">
                                            {'You want to get access to the demo?'}
                                        </_Builtin.Paragraph>
                                        <_Builtin.Paragraph className="text-color-light-grey">
                                            {'Contact us at '}
                                            <a href="mailto:bastien.s@xandimmersion.com">bastien.s@xandimmersion.com</a>
                                            {' and someone from our team will answer you!'}
                                        </_Builtin.Paragraph>
                                    </_Builtin.Block>
                                )}
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}
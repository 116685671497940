import {
    faBriefcase,
    faDatabase,
    faDice,
    faEnvelope,
    faPuzzlePiece,
    faRightFromBracket,
    faCompass,
    faHandshakeAngle,
    faLaptopCode,
    faPenToSquare,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Button,
    ButtonIcon,
    ButtonText,
    NavbarDivider,
    Sidebar,
    SidebarLayout,
} from 'devlink'
import { characterStore, Team } from 'entities/character/character.model'
import { sessionQueries } from 'entities/session'
import { ReactElement, useEffect, useLayoutEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { pathKeys } from 'shared/lib/react-router'

export function GenericLayout() {
    const location = useLocation()
    const isGuest = location.pathname.startsWith(pathKeys.login())

    return <>{isGuest ? <GuestNavigation /> : <UserNavigation />}</>
}

export function GuestLayout() {
    return (
        <>
            <GuestNavigation />
        </>
    )
}

export function NakedLayout() {
    return <Outlet />
}

function UserNavigation() {
    return (
        <>
            <SidebarLayout
                slotSidebarLayout={
                    <>
                        <SidebarContainer />
                        <Outlet />
                    </>
                }
            />
        </>
    )
}

function GuestNavigation() {
    return <Outlet />
}

function SidebarContainer() {
    const location = useLocation()
    const navigate = useNavigate()

    const initialTeam = {
        name: '',
        is_owner: 0,
        is_admin: 0,
        can_manage_models: 0,
        can_manage_npcs: 0,
        can_manage_datasets: 0,
        can_download_ariel: 0,
        can_download_diagen: 0,
        can_download_gepetto: 0,
        valid_until: '',
        valid_from: '',
    }
    const [selectedTeam, setSelectedTeam] = useState<Team>(characterStore.getState().currentTeam || initialTeam)

    useLayoutEffect(() => {
        const setComponentStateFromStore = (state: any) => {
            setSelectedTeam(state.currentTeam || initialTeam);
        };

        // Manually set the initial state from the store
        const initialState = characterStore.getState();
        setComponentStateFromStore(initialState);

        // Subscribe to future store changes
        const unsubscribe = characterStore.subscribe((state) => {
            if (state.npcs && state.currentTeam && state.images && state.audios) {
                setComponentStateFromStore(state);
            }
        });

        // Cleanup function to unsubscribe
        return () => {
            unsubscribe();
        };

    }, [])


    const initialNavlinksTop = [
        {
            label: 'Ariel',
            icon: './images/icon/arielicon.png',
            to: pathKeys.ariel(),
            isCurrent: location.pathname.startsWith(pathKeys.ariel()),
        },
        {
            label: 'Diagen',
            isCurrent: location.pathname.startsWith(pathKeys.diagen()),
            icon: './images/icon/diagenicon.png',
            to: pathKeys.diagen(),
        },
        {
            label: 'Logan',
            isCurrent: location.pathname.startsWith(pathKeys.logsanalysis()),
            icon: faPenToSquare,
            to: pathKeys.logsanalysis(),
        },
        // {
        //     label: 'Roll a Dice',
        //     isCurrent: location.pathname.startsWith(pathKeys.roll()),
        //     icon: faDice,
        //     to: pathKeys.roll(),
        // },
        {
            label: 'Game Helper',
            isCurrent: location.pathname.startsWith(pathKeys.gamehelper()),
            icon: faHandshakeAngle,
            to: pathKeys.gamehelper(),
        },
        {
            label: 'Geppetto',
            isCurrent: location.pathname.startsWith(pathKeys.geppetto()),
            icon: './images/icon/animationicon.png',
            to: pathKeys.geppetto(),
        },
        {
            label: 'Demos',
            isCurrent: location.pathname.startsWith(pathKeys.demos()),
            icon: faLaptopCode,
            to: pathKeys.demos(),
        },
        {
            label: 'Corporate Helper',
            isCurrent: location.pathname.startsWith(pathKeys.corporatehelper()),
            icon: faHandshakeAngle,
            to: pathKeys.corporatehelper(),
        },
        
    ]
    const initialNavlinksBottom = [
        {
            label: 'Dataset',
            isCurrent: location.pathname.startsWith(pathKeys.dataset()),
            icon: faDatabase,
            to: pathKeys.dataset(),
        },
        {
            label: 'Plug-ins',
            isCurrent: location.pathname.startsWith(pathKeys.plugins()),
            icon: faPuzzlePiece,
            to: pathKeys.plugins(),
        },
        {
            label: 'Data Usage',
            isCurrent: location.pathname.startsWith(pathKeys.datausage()),
            icon: faCompass,
            to: pathKeys.datausage(),
        },
        {
            label: 'Admin',
            isCurrent: location.pathname.startsWith(pathKeys.admin()),
            icon: faBriefcase,
            to: pathKeys.admin(),
        },
        {
            label: 'Contact',
            isCurrent: location.pathname.startsWith(pathKeys.contact()),
            icon: faEnvelope,
            to: pathKeys.contact(),
        },
    ]

    const [navlinksTop, setNavlinksTop] = useState(initialNavlinksTop);
    const [navlinksBottom, setNavlinksBottom] = useState(initialNavlinksBottom);
  
    useEffect(() => {
      const updatedNavlinksTop = initialNavlinksTop
        .filter((navlink) => {
          if (selectedTeam.name.toLowerCase() === 'hydac' || selectedTeam.name === 'Test') {
            return navlink.to === pathKeys.corporatehelper();
          }
          if (navlink.to === pathKeys.corporatehelper()) {
            return selectedTeam.name.toLowerCase() === 'xandimmersion';
          }
          return true;
        })
        .map((navlink) => ({
          ...navlink,
          isCurrent: location.pathname.startsWith(navlink.to),
        }));
  
      setNavlinksTop(updatedNavlinksTop);
  
      const updatedNavlinksBottom = initialNavlinksBottom
        .filter((navlink) => {
          if (selectedTeam.name.toLowerCase() === 'hydac' || selectedTeam.name === 'Test') {
            return navlink.to === pathKeys.corporatehelper() || navlink.to === pathKeys.contact();
          }
  
          if (selectedTeam.name.toLowerCase() !== 'xandimmersion' && navlink.to === pathKeys.corporatehelper()) {
            return false;
          }
  
          if (navlink.to === pathKeys.admin() && !selectedTeam.is_admin) return false;
  
          if (
            navlink.to === pathKeys.datausage() &&
            selectedTeam.name !== 'Embers' &&
            selectedTeam.name.toLowerCase() !== 'xandimmersion'
          ) {
            return false;
          }
  
          return true;
        })
        .map((navlink) => ({
          ...navlink,
          isCurrent: location.pathname.startsWith(navlink.to),
        }));
  
      setNavlinksBottom(updatedNavlinksBottom);
    }, [location.pathname, selectedTeam]);

    const { mutate: logout, isPending: isLogoutPending } = sessionQueries.useLogoutMutation()

    return (
        <Sidebar
            slotSidebarNavlinks={
                <>
                    {navlinksTop.map((navlink) => (
                        <Button
                            key={navlink.to}
                            rpButton={{
                                style: {
                                    cursor: `${navlink.isCurrent ? 'default' : 'pointer'}`,
                                },
                                className: `button is-navlink ${navlink.isCurrent ? '' : 'is-secondary'}`,
                                onClick: (e: React.MouseEvent) => {
                                    e.preventDefault()
                                    if (navlink.isCurrent) return
                                    navigate(navlink.to)
                                },
                            }}
                            slotButton={
                                <div className="is-navlink">
                                    <ButtonIcon
                                        buttonIconText={
                                            typeof navlink.icon === 'string' ? (
                                                <img
                                                    src={navlink.icon}
                                                    alt={navlink.label}
                                                    style={{ width: '30px', height: '20px', objectFit: 'contain', margin: '0 0.5rem 0.1rem' }}
                                                />
                                            ) : (
                                                <FontAwesomeIcon icon={navlink.icon} size="1x" />
                                            )
                                        }
                                    />
                                    <ButtonText buttonText={navlink.label} />
                                </div>
                            }
                        />
                    ))}

                    <NavbarDivider />

                    {navlinksBottom.map((navlink) => (
                        <Button
                            key={navlink.to}
                            rpButton={{
                                style: {
                                    cursor: `${navlink.isCurrent ? 'default' : 'pointer'}`,
                                },
                                className: `button is-navlink ${navlink.isCurrent ? '' : 'is-secondary'}`,
                                onClick: (e: React.MouseEvent) => {
                                    e.preventDefault()
                                    if (navlink.isCurrent) return
                                    navigate(navlink.to)
                                },
                            }}
                            slotButton={
                                <div className="is-navlink">
                                    <ButtonIcon
                                        buttonIconText={
                                            <FontAwesomeIcon
                                                icon={navlink.icon}
                                                size="1x"
                                            />
                                        }
                                    />
                                    <ButtonText buttonText={navlink.label} />
                                </div>
                            }
                        />
                    ))}
                </>
            }
            slotNavbarLogout={
                <>
                    {isLogoutPending ? (
                        <img
                            src="/images/xandi_running.gif"
                            alt="xandi running"
                            style={{ width: '36px' }}
                        />
                    ) : (
                        <>
                            <NavbarDivider />
                            <Button
                                rpButton={{
                                    className: 'button is-transparent is-navlink',
                                    onClick: (e: React.MouseEvent) => {
                                        e.preventDefault()
                                        logout()
                                    },
                                }}
                                slotButton={
                                    <div className="is-navlink">
                                        <FontAwesomeIcon
                                            icon={faRightFromBracket}
                                            size="2x"
                                        />
                                        <ButtonText buttonText="Log out" />
                                    </div>
                                }
                            />
                            <a
                                href="https://www.xandimmersion.com/terms-conditions"
                                className="button is-transparent is-navlink"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="is-navlink underline">
                                    <ButtonText buttonText="Terms & Conditions" />
                                </div>
                            </a>
                        </>
                    )}
                </>
            }
        />
    )
}

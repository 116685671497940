import { voiceModel } from 'entities/voice'
import { createContext, useState } from 'react'
import { StoreApi } from 'zustand'
import { fileStore, localizationStore, voiceStore } from './voice-page.model'

type VoiceContextType = {
    voiceStore: StoreApi<voiceModel.VoiceState>
    fileStore: StoreApi<voiceModel.VoiceFileState>
    localizationStore: StoreApi<voiceModel.VoiceLocalizationState>
    isModalRestartOpen: boolean
    setIsModalRestartOpen?: (value: boolean) => void
    isModalExportOpen?: boolean
    setIsModalExportOpen?: (value: boolean) => void
    isModalBestPracticeOpen?: boolean
    setIsModalBestPracticeOpen?: (value: boolean) => void
    isBestPracticeTrigger?: boolean
    setIsBestPracticeTrigger?: (value: boolean) => void
}

export const VoiceContext = createContext<VoiceContextType>({
    voiceStore,
    fileStore,
    localizationStore,
    isModalRestartOpen: false,
    isModalExportOpen: false,
    isModalBestPracticeOpen: false,
    isBestPracticeTrigger: false,
})

export const VoiceProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalRestartOpen, setIsModalRestartOpen] = useState(false)
    const [isModalExportOpen, setIsModalExportOpen] = useState(false)
    const [isModalBestPracticeOpen, setIsModalBestPracticeOpen] = useState(false)
    const [isBestPracticeTrigger, setIsBestPracticeTrigger] = useState(false)

    return (
        <VoiceContext.Provider
            value={{
                voiceStore,
                fileStore,
                localizationStore,
                isModalRestartOpen,
                setIsModalRestartOpen,
                isModalExportOpen,
                setIsModalExportOpen,
                isModalBestPracticeOpen,
                setIsModalBestPracticeOpen,
                isBestPracticeTrigger,
                setIsBestPracticeTrigger
            }}
        >
            {children}
        </VoiceContext.Provider>
    )
}

import {
    PopupNotification,
    SectionArielValues,
    SectionClosed,
    SectionLocalization,
    SectionVoiceBatch,
    SectionVoiceGeneration,
    VgAddBloc,
    VgGenerateLine,
    VgSelectCard,
    VoiceGenerationProcessingStateGenerating,
    VoiceGenerationProcessingStateReady,
} from 'devlink'
import { characterTypes } from 'entities/character'
import { emotionTypes } from 'entities/emotion'
import { voiceTypes } from 'entities/voice'
import { voiceRecordTypes } from 'entities/voice-record'
import {
    useCreateTextTranslationMutation,
    useCreateVoiceRecordMutation,
} from 'entities/voice-record/voice-record.queries'
import { VoiceContext } from 'pages/voice/voice-page.lib'
import { voiceStore } from 'pages/voice/voice-page.model'
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { SliderRange } from 'shared/ui/slider'

import { characterStore } from 'entities/character/character.model'
import { sessionStore } from 'entities/session/session.model'
import { FileState, Progress, TranslatedLine } from 'entities/voice/voice.model'
import {
    useCreateBatchStatus,
    useStartBatchGeneration,
    useTerminateBatchGeneration,
} from 'entities/voice/voice.queries'
type VoiceGenerationContainerProps = {
    characters: characterTypes.Npcs
}

type MultiSelectProps = {
    value: string
    label: string
}

export function VoiceGenerationContainer({
    characters,
}: VoiceGenerationContainerProps) {
    const { voiceStore } = useContext(VoiceContext)
    const { fileStore, localizationStore } = useContext(VoiceContext)
    const [batchSection, setBatchSection] = useState(false)
    const [voiceSection, setVoiceSection] = useState(false)
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })

    // Batch Generation Variables
    const [batchStatus, setBatchStatus] = useState<FileState>({})
    const [batchGenerationInProgress, setBatchGenerationInProgress] =
        useState(false)
    const [file, setFile] = useState<File | null>(
        fileStore.getState().file || null
    )
    const [filename, setFilename] = useState(file?.name || '')
    const [isGenerateEnabled, setIsGenerateEnabled] = useState(false)
    const [isDownloadEnabled, setIsDownloadEnabled] = useState(false)
    const [isTerminateEnabled, setIsTerminateEnabled] = useState(false)
    const [allRecords, setAllRecords] = useState(true)
    const [firstRecord, setFirstRecord] = useState<number | null>(null)
    const [lastRecord, setLastRecord] = useState<number | null>(null)
    const [lastModified, setLastModified] = useState<string | null | undefined>(
        null
    )

    // Localization Variables
    const languages = [
        'English',
        'Spanish',
        'French',
        'German',
        'Italian',
        'Portuguese',
        'Polish',
        'Turkish',
        'Russian',
        'Dutch',
        'Czech',
        'Arabic',
        'Chinese',
        'Japanese',
        'Korean',
    ]
    const [localizationSection, setLocalizationSection] = useState(false)
    const [localizationFile, setLocalizationFile] = useState<File | null>(
        localizationStore.getState().file || null
    )
    const [textColumn, setTextColumn] = useState<string | null>(
        localizationStore.getState().textColumn || null
    )
    const [localizationColumns, setLocalizationColumns] = useState<string[]>(
        localizationStore.getState().fileColumns || []
    )
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([])
    const [localizationProgress, setLocalizationProgress] = useState<
        Progress[]
    >(localizationStore.getState().progress || [])
    useLayoutEffect(() => {
        voiceStore.getState().load()
        fileStore.getState().load()
        localizationStore.getState().load()
        voiceStore.setState({
            selectedEmotion: { id: 0, name: 'None' },
        })
    }, [])

    const { mutate: createVoiceStatus, isError: isErrorCreateVoiceStatus } =
        useCreateBatchStatus(fileStore)

    const {
        mutate: startBatchGeneration,
        isError: isErrorStartBatchGeneration,
    } = useStartBatchGeneration(fileStore)

    const {
        mutate: terminateBatchGeneration,
        isError: isErrorTerminatingBatchGeneration,
    } = useTerminateBatchGeneration(fileStore)

    const {
        mutate: createTextTranslation,
        isSuccess: isSuccessCreateTextTranslation,
        isError: isErrorCreateTextTranslation,
        isPending: isPendingCreateTextTranslation,
        data: dataCreateTextTranslation,
    } = useCreateTextTranslationMutation()

    
    useLayoutEffect(() => {
        if (
            !isGenerateEnabled &&
            (isErrorStartBatchGeneration ||
                isErrorCreateVoiceStatus ||
                isErrorTerminatingBatchGeneration)
        ) {
            openPopup(
                '\ue91e',
                'Error starting batch generation',
                ('Error Message: ' + batchStatus?.message) as string
            )
        } else if (isGenerateEnabled && isErrorStartBatchGeneration) {
            openPopup(
                '\ue91e',
                'Error starting batch generation',
                ('Error Message: ' + batchStatus?.message) as string
            )
        }
        // }, [isGenerateEnabled])
    }, [
        isErrorStartBatchGeneration,
        isErrorCreateVoiceStatus,
        isErrorTerminatingBatchGeneration,
    ])

    //

    useLayoutEffect(() => {
        if (file && file !== null && file instanceof File) {
            fileStore.getState().setFile(file)
            setFilename(file.name)
            setLastModified(null)
            if (batchStatus.uuid) {
                createVoiceStatus({
                    file: null,
                    uuid: batchStatus.uuid,
                })
            } else {
                createVoiceStatus({
                    file: file,
                    uuid: null,
                })
            }
        }
    }, [file])

    useEffect(() => {
        return () => {
            localizationStore.getState().setSelectedLanguages(selectedLanguages);
        };
    }, [selectedLanguages]);
    
    useEffect(() => {
        const storedLanguages = localizationStore.getState().selectedLanguages || [];
        setSelectedLanguages(storedLanguages);
    }, []);

    useLayoutEffect(() => {
        if (!localizationFile) return

        if (
            localizationFile &&
            localizationFile !== null &&
            localizationFile instanceof File
        ) {
            localizationStore.getState().setFile(localizationFile)
        }

        // if file is csv
        if (localizationFile.name.endsWith('.csv')) {
            // read columns from csv
            const reader = new FileReader()
            reader.onload = (e) => {
                const text = e.target?.result as string
                const lines = text.split('\n')
                const columns = lines[0].split(';') // TODO: Make sure other separators will also work
                console.log('columns:', columns)
                setLocalizationColumns(columns)
                localizationStore.getState().setFileColumns(columns)

                let tempTextColumn = ''
                if (columns.includes('text')) {
                    tempTextColumn = 'text'
                } else {
                    tempTextColumn = columns[0]
                }
                setTextColumn(tempTextColumn)
                localizationStore.getState().setTextColumn(tempTextColumn)
            }
            // Actually start reading the file
            reader.readAsText(localizationFile)
        }
        // else if ( //TODO: Make sure xlsx also works
        //     localizationFile.name.endsWith('.xlsx') ||
        //     localizationFile.name.endsWith('.xls') ||
        //     localizationFile.name.endsWith('.xlsm') ||
        //     localizationFile.name.endsWith('.xlsb')
        // ) {
        //     // Handle Excel files
        //     const reader = new FileReader()
        //     reader.onload = (e) => {
        //         try {
        //             const data = e.target?.result

        //             const workbook = XLSX.read(data, { type: 'binary' })
        //             const firstSheet = workbook.SheetNames[0]
        //             const worksheet = workbook.Sheets[firstSheet]
        //             const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        //                 header: 1,
        //             })

        //             if (jsonData.length > 0) {
        //                 const columns = jsonData[0] as string[]
        //                 console.log('columns:', columns)
        //                 setLocalizationColumns(columns)
        //                 localizationStore.getState().setFileColumns(columns)
        //                 const textColumn = columns.includes('text')
        //                     ? 'text'
        //                     : columns[0]
        //                 localizationStore.getState().setTextColumn(textColumn)
        //                 setTextColumn(textColumn)
        //                 //Create a variable for all rows with text and translation columns, everytime I translate a row I add a row.
        //             }
        //         } catch (error) {
        //             console.error('Error parsing Excel file:', error)
        //             openPopup(
        //                 '\ue91e',
        //                 'Error parsing file',
        //                 'There was an error reading the Excel file. Please check the format and try again.'
        //             )
        //         }
        //     }
        //     // Actually start reading the file
        //     reader.readAsBinaryString(localizationFile)
        // }
        else {
            openPopup(
                '\ue91e',
                'Unsupported file type',
                'Please upload a file with one of the following extensions: .csv, .xlsx, .xls, .xlsm, .xlsb.'
            )
        }
    }, [localizationFile])

    useLayoutEffect(() => {
        if (batchSection) {
            setAllRecords(true)
        }
    }, [batchSection])

    // subscribe to file store and set batch status if it changes
    useLayoutEffect(() => {
        fileStore.subscribe((state) => {
            setBatchStatus(state)

            if (state.status === 'starting') {
                setBatchGenerationInProgress(true)
            } else {
                setBatchGenerationInProgress(false)
            }
        })
    }, [])

    useLayoutEffect(() => {
        if (isSuccessCreateTextTranslation) {
            dataCreateTextTranslation.language =
                dataCreateTextTranslation.language || 'English'
            let data = localizationStore.getState().data || []
            const index = data.findIndex(
                (item) => item.language === dataCreateTextTranslation.language
            )
            if (index === undefined || index === -1) {
                console.log('Language not found')
                return
            }

            const translationTextIndex = data[index]?.text?.findIndex(
                (item) => item.text === dataCreateTextTranslation.input_text
            )
            if (
                translationTextIndex === undefined ||
                translationTextIndex === -1
            ) {
                console.log('Error in translation index')
                return
            }

            const totalTranslations = data[index]?.text?.length || 0
            const filledTranslations =
                data[index]?.text?.filter((item) => item.translation)?.length ??
                0

            // Update the translation
            if (
                data[index]?.text &&
                data[index]?.text?.[translationTextIndex]
            ) {
                // Create a temporary variable to ensure TypeScript recognizes this is defined
                const textArray = data[index].text! // Non-null assertion is safe here because of the check above
                textArray[translationTextIndex].translation =
                    dataCreateTextTranslation.response
                console.log('Translation updated successfully')
            } else {
                console.error('Invalid data structure or translation index')
                return
            }

            localizationStore.getState().setData(data)
            const progress = localizationStore.getState().progress || []
            const translationProgress =
                ((filledTranslations + 1) / totalTranslations) * 100
            progress[index] = {
                language: dataCreateTextTranslation.language,
                progress: translationProgress.toFixed(2) + '%',
            }
            localizationStore.getState().setProgress(progress)
            setLocalizationProgress(progress)

            if (filledTranslations + 1 === totalTranslations) {
                // Mark translation as complete
                const running = localizationStore.getState().running || []
                running[index] = {
                    running: false,
                    language: dataCreateTextTranslation.language,
                }
                localizationStore.getState().setRunning(running)
                openPopup(
                    '\ue917',
                    'Finished translation',
                    'You can now download the generated voice lines.'
                )
            }
            startTranslation(dataCreateTextTranslation.language)
        }

        if (isErrorCreateTextTranslation) {
            console.error('Translation Error:', isErrorCreateTextTranslation)
        }
    }, [isErrorCreateTextTranslation, isSuccessCreateTextTranslation])

    function startTranslation(language: string) {
        const progress = localizationStore.getState().progress || []
        const running = localizationStore.getState().running || []
        const data = localizationStore.getState().data || []
        const index = data.findIndex((item) => item.language === language)

        // First element with empty translation
        const textToTranslate =
            localizationStore
                ?.getState()
                ?.data?.[index].text?.find((t) => t.translation === '') ||
            undefined
        if (!textToTranslate) {
            console.log('No more text to translate')
            const newRunning = [...running]
            newRunning[index] = {
                language,
                running: false,
            }
            localizationStore.getState().setRunning(newRunning)
            openPopup(
                '\ue917',
                'Translation finished already',
                'You can now download the generated voice lines.'
            )
            return
        }

        // Check if running is set to false --> It was manually terminated
        if (running[index]?.running === false) {
            console.log('Translation process was terminated')
            return
        }
        createTextTranslation({
            textTranslation: {
                text: textToTranslate.text,
                language: language,
            },
        })
    }
    // if the batch generation is in progress, poll the API for the status
    useEffect(() => {
        if (batchGenerationInProgress) {
            const interval = setInterval(() => {
                if (batchStatus.uuid) {
                    createVoiceStatus({
                        file: null,
                        uuid: batchStatus.uuid,
                    })
                } else {
                    createVoiceStatus({
                        file: file,
                        uuid: null,
                    })
                }
            }, 10000)
            return () => clearInterval(interval)
        }
    }, [batchGenerationInProgress])

    useEffect(() => {
        if (batchStatus?.progress === '100%') {
            setIsDownloadEnabled(true)
            if (
                batchStatus.last_modified &&
                lastModified &&
                batchStatus.last_modified !== lastModified
            ) {
                openPopup(
                    '\ue917',
                    'Finished batch generation',
                    'You can now download the generated voice lines.'
                )
            }
            setLastModified(batchStatus.last_modified)
        } else {
            setIsDownloadEnabled(false)
        }

        if (
            batchStatus.progress &&
            Number(batchStatus.progress.split('%')[0]) >= 0 &&
            Number(batchStatus.progress.split('%')[0]) < 100 &&
            batchStatus.status === 'starting'
        ) {
            setIsTerminateEnabled(true)
            setIsGenerateEnabled(false)
        } else {
            setIsTerminateEnabled(false)
            setIsGenerateEnabled(true)
        }
    }, [batchStatus])

    
    // save the voice store when the component unmounts
    useLayoutEffect(() => {
        return () => {
            voiceStore.getState().save()
            fileStore.getState().save()
        }
    }, [])

    useLayoutEffect(() => {
        if (file) {
            fileStore.getState().setFile(file)

            // call the API to get the state of the batch generation
        }
    }, [file])

    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }

    return (
        <>
            <SectionArielValues
                rpOpenVoiceLab={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        setVoiceSection((prev) => !prev)
                    },
                }}
                rpOpenBatchGeneration={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        setBatchSection((prev) => !prev)
                    },
                }}
                rpOpenVoiceLocalization={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        setLocalizationSection((prev) => !prev)
                    },
                }}
            />
            {voiceSection ? (
                <SectionVoiceGeneration
                    slotCharacterVoiceCard={
                        <>
                            <SectionVoiceNpcSelectCard
                                characters={characters}
                            />
                        </>
                    }
                    slotVoiceCards={
                        <>
                            <SectionVoiceGenerationCards />
                        </>
                    }
                    onClose={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setVoiceSection(false)
                        },
                    }}
                />
            ) : (
                <SectionClosed
                    rpButtonOpen={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setVoiceSection(true)
                        },
                    }}
                    rpIcon={'\ue902'}
                    title={'Voice Lab'}
                    subtitle={'Try out unique voices that fit your NPCs.'}
                />
            )}
            {batchSection ? (
                <>
                    <SectionVoiceBatch
                        onClose={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                setBatchSection(false)
                            },
                        }}
                        rpAllRecords={{
                            checked: allRecords,
                            onChange: (e: Event) => {
                                e.preventDefault()
                                const target = e.target as HTMLInputElement
                                setAllRecords(target.checked)
                            },
                        }}
                        rpFilename={filename}
                        rpSetFirstRecord={{
                            onKeyUp: (e: Event) => {
                                e.preventDefault()
                                const target = e.target as HTMLInputElement
                                const inputValue = target.value
                                const numericValue = parseInt(inputValue, 10)
                                if (!isNaN(numericValue)) {
                                    setFirstRecord(numericValue)
                                } else if (inputValue === '') {
                                    setFirstRecord(null)
                                }
                            },
                        }}
                        rpSetLastRecord={{
                            onKeyUp: (e: Event) => {
                                e.preventDefault()
                                const target = e.target as HTMLInputElement
                                const inputValue = target.value
                                const numericValue = parseInt(inputValue, 10)
                                if (!isNaN(numericValue)) {
                                    setLastRecord(numericValue)
                                } else if (inputValue === '') {
                                    setLastRecord(null)
                                }
                            },
                        }}
                        rpFileUpload={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                const input = document.createElement('input')
                                input.type = 'file'
                                input.accept = '.csv, .xlsx, .xls'
                                input.onchange = (e) => {
                                    const target = e.target as HTMLInputElement
                                    if (target.files) {
                                        const fileExtension =
                                            target.files[0].name
                                                .split('.')
                                                .pop()
                                        if (
                                            ['csv', 'xlsx', 'xls'].includes(
                                                fileExtension || ''
                                            )
                                        ) {
                                            openPopup(
                                                '\ue917',
                                                'File uploaded successfully',
                                                'You can now generate voice lines for the characters in the file.'
                                            )
                                            setFile(target.files[0])
                                        } else {
                                            openPopup(
                                                '\ue91e',
                                                'Invalid file type',
                                                'Choose a file with a .csv, .xlsx, or .xls extension.'
                                            )
                                        }
                                    }
                                }
                                input.click()
                            },
                            onDragOver: (e: Event) => {
                                e.preventDefault()
                            },
                            onDrop: (e: Event) => {
                                e.preventDefault()
                                const target = e as DragEvent
                                if (target.dataTransfer) {
                                    const file = target.dataTransfer.files[0]
                                    const fileExtension = file.name
                                        .split('.')
                                        .pop()
                                    if (
                                        ['csv', 'xlsx', 'xls'].includes(
                                            fileExtension || ''
                                        )
                                    ) {
                                        openPopup(
                                            '\ue917',
                                            'File uploaded successfully',
                                            'You can now generate voice lines for the characters in the file.'
                                        )
                                        setFile(file)
                                    } else {
                                        openPopup(
                                            '\ue91e',
                                            'Invalid file type',
                                            'Choose a file with a .csv, .xlsx, or .xls extension.'
                                        )
                                    }
                                }
                            },
                        }}
                        rpFileDelete={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                setFile(null)
                                setFilename('')
                                setBatchStatus({})
                                fileStore.getState().reset()
                                setAllRecords(true)
                            },
                        }}
                        rpStatus={batchStatus}
                        rpBatchGeneration={{
                            onClick: (e: Event) => {
                                if (isGenerateEnabled) {
                                    startBatchGeneration({
                                        file: file,
                                        line_start: allRecords
                                            ? null
                                            : firstRecord,
                                        line_end: allRecords
                                            ? null
                                            : lastRecord,
                                    })
                                    setBatchStatus({
                                        ...batchStatus,
                                        progress: '0%',
                                    })
                                    setBatchGenerationInProgress(true)
                                }
                            },
                            className: `button is-secondary-light ${isGenerateEnabled ? '' : 'is-disabled'}`,
                            text: isGenerateEnabled
                                ? 'Batch Generation...'
                                : 'Processing...',
                        }}
                        rpFileDownload={{
                            onClick: (e: Event) => {
                                if (batchStatus?.url && isDownloadEnabled) {
                                    const a = document.createElement('a')
                                    a.href = batchStatus.url
                                    a.download =
                                        batchStatus.url.split('/').pop() || ''
                                    a.style.display = 'none'
                                    document.body.appendChild(a)
                                    a.click()
                                    document.body.removeChild(a)
                                }
                            },
                            className: `button is-secondary-light ${isDownloadEnabled ? '' : 'is-disabled'}`,
                        }}
                        rpBatchTerminate={{
                            onClick: (e: Event) => {
                                if (isTerminateEnabled) {
                                    if (batchStatus.uuid) {
                                        terminateBatchGeneration({
                                            file: null,
                                            uuid: batchStatus.uuid,
                                        })
                                    } else {
                                        terminateBatchGeneration({
                                            file: file,
                                            uuid: null,
                                        })
                                    }
                                    setIsTerminateEnabled(false)
                                }
                            },
                            className: `button is-small is-red ${isTerminateEnabled ? '' : 'is-small is-disabled'}`,
                            text: isTerminateEnabled
                                ? 'Terminate Process'
                                : 'Terminating...',
                        }}
                        rpProgressBarVisible={batchGenerationInProgress}
                    />
                </>
            ) : (
                        <SectionClosed
                            rpButtonOpen={{
                                onClick: (e: Event) => {
                                    e.preventDefault()
                                    setBatchSection(true)
                                },
                            }}
                            rpIcon={'\ue902'}
                            title={'Batch Generation'}
                            subtitle={
                                'Instantly generate and localize all voice lines from your csv file.'
                            }
                        />
                    )}   
            {localizationSection ? (
                <>
                    <SectionLocalization
                        onClose={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                setLocalizationSection(false)
                            },
                        }}
                        rpFilename={localizationFile?.name || ''}
                        rpFileUpload={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                const input = document.createElement('input')
                                input.type = 'file'
                                input.accept = '.csv, .xlsx, .xls'
                                input.onchange = (e) => {
                                    const target = e.target as HTMLInputElement
                                    if (target.files) {
                                        const fileExtension =
                                            target.files[0].name
                                                .split('.')
                                                .pop()
                                        if (
                                            ['csv', 'xlsx', 'xls'].includes(
                                                fileExtension || ''
                                            )
                                        ) {
                                            openPopup(
                                                '\ue917',
                                                'File uploaded successfully',
                                                'You can now translate your voice lines in the file.'
                                            )
                                            setLocalizationFile(target.files[0])
                                        } else {
                                            openPopup(
                                                '\ue91e',
                                                'Invalid file type',
                                                'Choose a file with a .csv, .xlsx, or .xls extension.'
                                            )
                                        }
                                    }
                                }
                                input.click()
                            },
                            onDragOver: (e: Event) => {
                                e.preventDefault()
                            },
                            onDrop: (e: Event) => {
                                e.preventDefault()
                                const target = e as DragEvent
                                if (target.dataTransfer) {
                                    const file = target.dataTransfer.files[0]
                                    const fileExtension = file.name
                                        .split('.')
                                        .pop()
                                    if (
                                        ['csv', 'xlsx', 'xls'].includes(
                                            fileExtension || ''
                                        )
                                    ) {
                                        openPopup(
                                            '\ue917',
                                            'File uploaded successfully',
                                            'You can now generate voice lines for the characters in the file.'
                                        )
                                        setFile(file)
                                    } else {
                                        openPopup(
                                            '\ue91e',
                                            'Invalid file type',
                                            'Choose a file with a .csv, .xlsx, or .xls extension.'
                                        )
                                    }
                                }
                            },
                        }}
                        rpFileDelete={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                setLocalizationFile(null)
                                setTextColumn(null)
                                setLocalizationColumns([])
                                setSelectedLanguages([])
                                localizationStore.getState().reset()
                            },
                        }}
                        rpRunning={localizationStore.getState().running}
                        rpStatus={localizationProgress}
                        rpExecute={{
                            onClick: async (e: Event) => {
                                e.preventDefault()

                                // Get the clicked button element
                                const clickedButton =
                                    e.currentTarget as HTMLElement
                                // const index = parseInt(clickedButton.id.split('-')[1], 10);
                                const language = clickedButton.id.split('-')[0]

                                // Check if language already exists in data
                                const existingLanguage = Array.isArray(
                                    localizationProgress
                                )
                                    ? localizationProgress.find(
                                          (item) => item.language === language
                                      )
                                    : undefined
                                if (existingLanguage) {
                                    // Continue translating
                                    console.log(
                                        'Language already exists in data. Continuing...'
                                    )
                                    let allRunning =
                                        localizationStore.getState().running ||
                                        []
                                    const existingIndex = allRunning.findIndex(
                                        (item) => item.language === language
                                    )
                                    allRunning[existingIndex].running = true
                                    localizationStore
                                        .getState()
                                        .setRunning(allRunning)

                                    startTranslation(language)
                                }

                                if (
                                    isGenerateEnabled &&
                                    localizationFile &&
                                    textColumn &&
                                    selectedLanguages.length > 0
                                ) {
                                    const reader = new FileReader()
                                    reader.onload = async (event) => {
                                        const text = event.target
                                            ?.result as string
                                        const lines = text.split('\n')
                                        const columns = lines[0].split(';')
                                        const textColumnIndex =
                                            columns.indexOf(textColumn)

                                        if (textColumnIndex === -1) {
                                            openPopup(
                                                '\ue91e',
                                                'Error',
                                                'The specified text column does not exist in the file.'
                                            )
                                            return
                                        }

                                        const textsToTranslate = lines
                                            .slice(1)
                                            .map(
                                                (line) =>
                                                    line.split(';')[
                                                        textColumnIndex
                                                    ]
                                            )

                                        let translationData: TranslatedLine[] =
                                            []
                                        for (const text of textsToTranslate) {
                                            if (text && text.trim()) {
                                                const newTranslation: TranslatedLine =
                                                    {
                                                        text: text,
                                                        translation: '',
                                                        language: language,
                                                    }
                                                translationData.push(
                                                    newTranslation
                                                )
                                            }
                                        }
                                        // update the voice store with Translation
                                        let allTranslationData =
                                            localizationStore.getState().data ||
                                            []
                                        let allProgress =
                                            localizationStore.getState()
                                                .progress || []
                                        let allRunning =
                                            localizationStore.getState()
                                                .running || []
                                        if (
                                            allTranslationData.find(
                                                (item) =>
                                                    item.language === language
                                            )
                                        ) {
                                            const existingIndex =
                                                allTranslationData.findIndex(
                                                    (item) =>
                                                        item.language ===
                                                        language
                                                )
                                            allTranslationData[
                                                existingIndex
                                            ].text = translationData
                                            allProgress[
                                                existingIndex
                                            ].progress = '0%'
                                            allRunning[existingIndex].running =
                                                true
                                        } else {
                                            allTranslationData.push({
                                                text: translationData,
                                                language: language,
                                            })
                                            allProgress.push({
                                                progress: '0%',
                                                language: language,
                                            })
                                            allRunning.push({
                                                running: true,
                                                language: language,
                                            })
                                        }
                                        localizationStore
                                            .getState()
                                            .setData(allTranslationData)
                                        localizationStore
                                            .getState()
                                            .setProgress(allProgress)
                                        localizationStore
                                            .getState()
                                            .setRunning(allRunning)
                                        setLocalizationProgress(allProgress)
                                        startTranslation(language)
                                    }
                                    reader.readAsText(localizationFile)
                                } else {
                                    openPopup(
                                        '\ue91e',
                                        'Error',
                                        'Please ensure a file is uploaded, a text column is selected, and at least one language is chosen.'
                                    )
                                }
                            },
                            className: `button is-secondary-light ${isGenerateEnabled ? '' : 'is-disabled'}`,
                        }}
                        rpTerminate={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                // Get the clicked button element
                                const clickedButton =
                                    e.currentTarget as HTMLElement
                                // const index = parseInt(clickedButton.id.split('-')[1], 10);
                                const language = clickedButton.id.split('-')[1]
                                // Check if language already exists in data
                                let allRunning =
                                    localizationStore.getState().running || []
                                // Update the running state to false if the language exists
                                const index = allRunning.findIndex(
                                    (item) => item.language === language
                                )
                                if (index !== -1) {
                                    allRunning[index].running = false
                                    localizationStore
                                        .getState()
                                        .setRunning(allRunning)
                                }
                                openPopup(
                                    '\ue91e',
                                    'Localization Terminated',
                                    'The localization process has been stopped.'
                                )
                            },
                            text: 'Terminate Process',
                        }}
                        rpColumnDropdown={{
                            placeholder: 'Choose your text column',
                            isDisabled: false,
                            value: {
                                label: textColumn,
                                index: localizationColumns
                                    .findIndex(
                                        (column) => column === textColumn
                                    )
                                    .toString(),
                            },
                            options: localizationColumns.map(
                                (column, index) => ({
                                    label: column,
                                    value: index.toString(),
                                })
                            ),
                            onChange: (target: any) => {
                                console.log('selected column:', target.label)
                                setTextColumn(target.label)
                                localizationStore
                                    .getState()
                                    .setTextColumn(target.label)
                            },
                        }}
                        rpLanguagesDropdown={{
                            placeholder: 'Choose your target languages',
                            isDisabled: localizationColumns.length === 0,
                            value: selectedLanguages
                                .map((language) => ({
                                    label: language,
                                    value: languages
                                        .indexOf(language)
                                        .toString(),
                                }))
                                .sort((a, b) => a.label.localeCompare(b.label)),
                            options: languages
                                .map((language, index) => ({
                                    label: language,
                                    value: index.toString(),
                                }))
                                .sort((a, b) => a.label.localeCompare(b.label)),
                            onChange: (targets: any) => {
                                //TODO: This will not work if you remove languages and change the order
                                const targetLanguages = targets.map(
                                    (target: any) => target.label
                                )
                                setSelectedLanguages(targetLanguages)
                                localizationStore
                                    .getState()
                                    .setSelectedLanguages(targetLanguages)
                            },
                        }}
                        rpButtonDownload={{
                            className: `button is-secondary-light ${
                                isPendingCreateTextTranslation || !localizationStore.getState().data?.length ? 'is-disabled' : ''
                            }`,
                            onClick: (e: Event) => {
                                e.preventDefault()
                                // Get the clicked button element
                                const clickedButton =
                                    e.currentTarget as HTMLElement
                                // const index = parseInt(clickedButton.id.split('-')[1], 10);
                                const language = clickedButton.id.split('-')[1]

                                const a = document.createElement('a')
                                // Create csv file from data
                                const data = localizationStore.getState().data?.find(item => item.language === language)?.text
                                if (!data) {
                                    openPopup(
                                        '\ue91e',
                                        'Error',
                                        'No data to download'
                                    )
                                    return
                                }
                                const csvContent =
                                    'data:text/csv;charset=utf-8,' +
                                    'Text;Translation;Language\n' +
                                    data
                                        .map((item) =>
                                            `"${item.text}";"${item.translation}";"${item.language}"`
                                        )
                                        .join('\n')
                                const encodedUri = encodeURI(csvContent)
                                a.href = encodedUri
                                a.download = `${language}.csv`
                                a.style.display = 'none'
                                document.body.appendChild(a)
                                a.click()

                            }
                        }}

                    />
                </>
            ) : (
                <SectionClosed
                    rpButtonOpen={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setLocalizationSection(true)
                        },
                    }}
                    rpIcon={'\ue902'}
                    title={'Text localization'}
                    subtitle={
                        'Localize your dialogue lines into several languages instantly.'
                    }
                />
            )}
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}

type VoiceNpcSelectCardContainerProps = {
    characters: characterTypes.Npcs
}

async function checkFileExists(filePath: string) {
    try {
        const response = await fetch(filePath)

        // The fetch was successful, so the file likely exists
        if (response.status === 200) {
            const contentType = response.headers.get('content-type')

            // If the content type is what you expect, the file likely exists
            if (contentType && contentType !== 'text/html; charset=utf-8') {
                return true
            }
        }

        // The fetch was not successful, so the file likely does not exist
        return false
    } catch (error) {
        // An error occurred while trying to fetch the file, so it likely does not exist
        return false
    }
}

function SectionVoiceNpcSelectCard(props: VoiceNpcSelectCardContainerProps) {
    const [characterImage, setCharacterImage] = useState<string | null>(null)
    const [selectedTeam, setSelectedTeam] = useState<string | null | undefined>(
        characterStore.getState().currentTeam?.name || null
    )
    const { characters } = props
    const { voiceStore } = useContext(VoiceContext)
    const [selectedCharacter, setSelectedCharacter] = useState<
        characterTypes.Npc | null | undefined
    >(null)
    const [selectedEmotion, setSelectedEmotion] = useState<
        emotionTypes.Emotion | null | undefined
    >(null)
    const [audioSample, setAudioSample] = useState('')
    const [audioSampleExists, setAudioSampleExists] = useState(true)
    const defaultEmotion = {
        t: 'neutral',
        v: '0',
    }
    const [emotionOptions, setEmotionOptions] = useState([defaultEmotion])
    const defaultValue = {
        t: 'Choose your NPC',
        v: '',
    }
    const characterOptions = [
        defaultValue,
        ...characters?.map((character) => ({
            t: character?.name,
            v: character?.id.toString(),
        })),
    ]

    useLayoutEffect(() => {
        setSelectedCharacter(voiceStore.getState().selectedCharacter)
        setSelectedEmotion(voiceStore.getState().selectedEmotion)
    }, [])

    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (selectedTeam && state.currentTeam?.name !== selectedTeam) {
                setCharacterImage(null)
                setSelectedTeam(state.currentTeam?.name)
                setSelectedCharacter(null)
                setCharacterImage(null)
                voiceStore.getState().reset()
            }
        })
    }, [selectedTeam])

    useEffect(() => {
        if (selectedCharacter) {
            checkFileExists(
                `/voice-samples/${selectedCharacter.name}.wav`
            ).then((exists) => {
                setAudioSampleExists(exists)
            })

            const currentState = characterStore.getState().images
            const characterImage = currentState?.find(
                (image) => image.name === selectedCharacter.name
            )
            setCharacterImage(characterImage?.image || null)
            setEmotionOptions(
                selectedCharacter.emotion.map((emotion, index) => ({
                    t: emotion,
                    v: index.toString(),
                }))
            )
        } else {
            setCharacterImage(null)
            setEmotionOptions([defaultEmotion])
        }
    }, [selectedCharacter])

    useLayoutEffect(() => {
        if (!selectedCharacter) return
        setAudioSample(`/voice-samples/${selectedCharacter.name}.wav`)
    }, [selectedCharacter])

    const audioRef = useRef<HTMLAudioElement>(null)

    function playSample() {
        if (!audioRef.current || audioSample === '') return

        if (audioRef.current.paused) {
            audioRef.current.currentTime = 0
            audioRef.current.play()
        } else {
            audioRef.current.pause()
        }
    }

    return (
        <>
            <VgSelectCard
                characterImage={
                    selectedCharacter && selectedCharacter.name === 'Player'
                        ? '/images/blank.png'
                        : characterImage || '/images/blank.png'
                }
                rpDropdownCharacter={{
                    label: 'Choose your NPC',
                    options: characterOptions.filter(
                        (character) => character.t !== 'Player'
                    ),
                    value: selectedCharacter?.id.toString() || '',
                    name: selectedCharacter?.name,
                    onChange: (e: Event) => {
                        const target = e.target as HTMLSelectElement
                        const selectedCharacter = characters.find(
                            (character) =>
                                character.id.toString() === target.value
                        )
                        setSelectedCharacter(selectedCharacter || null)
                        voiceStore.setState({
                            selectedCharacter: selectedCharacter,
                        })

                        if (selectedCharacter) {
                            setSelectedEmotion(null)
                            voiceStore.setState({
                                selectedEmotion: {
                                    id: 0,
                                    name: 'None',
                                },
                            })
                        }
                    },
                }}
                rpDropdownEmotion={{
                    label: 'Choose an emotion',
                    options: emotionOptions,
                    value: selectedEmotion?.id.toString() || '',
                    onChange: (e: Event) => {
                        const target = e.target as HTMLSelectElement
                        const selectedEmotion = emotionOptions.find(
                            (emotion) => emotion.v === target.value
                        )
                        setSelectedEmotion({
                            id: Number(selectedEmotion?.v),
                            name: selectedEmotion?.t || 'None',
                        })
                        voiceStore.setState({
                            selectedEmotion: {
                                id: Number(selectedEmotion?.v),
                                name: selectedEmotion?.t || 'None',
                            },
                        })
                    },
                }}
                rpButtonPlaySample={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        playSample()
                    },
                    state: audioRef.current,
                }}
                // emotionSpeaker={emotions?.speakers}
                audioSampleExists={audioSampleExists}
                emotions={selectedCharacter?.emotion}
            />
            <audio ref={audioRef} src={audioSample} />
        </>
    )
}

function SectionVoiceGenerationCards() {
    const { voiceStore } = useContext(VoiceContext)
    const [voiceRecords, setVoiceRecords] = useState(
        voiceStore.getState().voiceRecords || []
    )
    const [isNpcCardFilled, setIsNpcCardFilled] = useState(
        Boolean(voiceStore.getState().selectedCharacter)
    )
    const [languageOptions, setLanguageOptions] = useState<any[]>([])
    const [selectedLanguage, setSelectedLanguage] =
        useState<voiceTypes.Language>({ id: 0, name: 'English' })
    const currentCharacterNameRef = useRef('')

    useEffect(() => {
        const unsubscribeVoiceStore = voiceStore.subscribe((state) => {
            setVoiceRecords((prevRecords) =>
                JSON.stringify(state.voiceRecords) !==
                JSON.stringify(prevRecords)
                    ? state.voiceRecords || prevRecords
                    : prevRecords
            )

            const newCharacterName = state.selectedCharacter?.name || ''
            if (newCharacterName !== currentCharacterNameRef.current) {
                currentCharacterNameRef.current = newCharacterName
                setIsNpcCardFilled(!!state.selectedCharacter)

                if (!state.selectedCharacter) {
                    voiceStore.getState().reset()
                }

                if (state.selectedCharacter?.language) {
                    const newLanguageOptions = state.selectedCharacter.language
                        .map((language, index) => ({
                            t: language,
                            v: (index + 1).toString(),
                        }))
                        .sort((a, b) => a.t.localeCompare(b.t))

                    setLanguageOptions((prevOptions) =>
                        JSON.stringify(prevOptions) !==
                        JSON.stringify(newLanguageOptions)
                            ? newLanguageOptions
                            : prevOptions
                    )

                    const englishOption = newLanguageOptions.find(
                        (opt) => opt.t === 'English'
                    )
                    setSelectedLanguage(
                        englishOption
                            ? {
                                  id: Number(englishOption.v),
                                  name: englishOption.t,
                              }
                            : {
                                  id: Number(newLanguageOptions[0]?.v),
                                  name: newLanguageOptions[0]?.t,
                              }
                    )
                }
            }
        })

        return () => unsubscribeVoiceStore()
    }, [])

    return (
        <>
            {voiceRecords?.map((voiceRecord, index) => {
                return (
                    <VoiceGenerationCard
                        // key={index}
                        index={index}
                        voiceRecord={voiceRecord}
                        isNpcCardFilled={isNpcCardFilled}
                        // selectedCharacter={selectedCharacter}
                        // selectedEmotion={selectedEmotion}
                        languageOptions={languageOptions}
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                        // characters={characters}
                        // voices={voices}
                    />
                )
            })}
            <VgAddBloc
                rpAddBlocButton={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        voiceStore.getState().addVoiceRecord({
                            sentence: '',
                            convergence: 0.5,
                            speed: 1,
                            emotion:
                                voiceStore.getState().selectedEmotion?.name ||
                                undefined,
                        })
                    },
                }}
            />
        </>
    )
}

type VoiceGenerationCardProps = {
    index: number
    voiceRecord: voiceRecordTypes.VoiceRecord
    isNpcCardFilled: boolean
    languageOptions: any[]
    selectedLanguage: voiceTypes.Language
    setSelectedLanguage: (language: voiceTypes.Language) => void
}
function VoiceGenerationCard(props: VoiceGenerationCardProps) {
    const {
        index,
        voiceRecord,
        isNpcCardFilled,
        languageOptions,
        selectedLanguage,
        setSelectedLanguage,
    } = props
    const [voiceRecordText, setVoiceRecordText] = useState(voiceRecord.sentence)
    const [isTranslateChecked, setIsTranslateChecked] = useState(false)
    const [voiceRecordConvergence, setVoiceRecordConvergence] = useState(
        voiceRecord?.convergence || 0.1
    )
    const [voiceRecordSpeed, setVoiceRecordSpeed] = useState(
        voiceRecord?.speed || 1
    )
    const [audioUrl, setAudioUrl] = useState(voiceRecord.audio || '')
    const audioRef = useRef<HTMLAudioElement>(null)
    const [isGenerateButtonEnabled, setIsGenerateButtonEnabled] =
        useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const {
        mutate: createVoiceRecord,
        isPending: isCreatingVoiceRecord,
        isSuccess: isVoiceRecordCreated,
        isError: isVoiceRecordError,
        error: isVoiceRecordErrorData,
        data: voiceRecordData,
        reset: resetVoiceRecord,
    } = useCreateVoiceRecordMutation(voiceStore)

    useLayoutEffect(() => {
        setIsGenerateButtonEnabled(
            isNpcCardFilled &&
                !!voiceRecordText &&
                !Number.isNaN(voiceRecordConvergence) &&
                !Number.isNaN(voiceRecordSpeed) &&
                !isCreatingVoiceRecord
        )
    }, [
        voiceRecordText,
        voiceRecordConvergence,
        voiceRecordSpeed,
        isNpcCardFilled,
        isCreatingVoiceRecord,
    ])

    // TODO: Remove this subscribe method! It exists in parent class -> do we really need this?
    useLayoutEffect(() => {
        voiceStore.subscribe((state) => {
            if (state.voiceRecords && state.voiceRecords[index]) {
                setVoiceRecordText(state.voiceRecords[index].sentence)
            }
        })
    }, [index, voiceRecord])

    useLayoutEffect(() => {
        if (isVoiceRecordCreated) {
            voiceStore.getState().updateVoiceRecord(index, {
                sentence: voiceRecordData.sentence,
                translation: voiceRecordData.translation,
                convergence: voiceRecordData.convergence,
                emotion: voiceRecordData.emotion,
                audio: voiceRecordData.audio,
            })
            setAudioUrl(voiceRecordData.audio || '')
        }
    }, [isVoiceRecordCreated, voiceRecordData])

    function playAudio() {
        if (!audioRef.current || audioUrl === '') return
        if (audioRef.current.paused) {
            audioRef.current.currentTime = 0
            setIsPlaying(true)
            audioRef.current.play()
            setTimeout(() => {
                setIsPlaying(false)
            }, audioRef.current.duration * 1000)
        } else {
            setIsPlaying(false)
            audioRef.current.pause()
        }
    }

    function downloadAudio() {
        if (!audioRef.current || audioUrl === '') return
        const a = document.createElement('a')
        a.href = audioRef.current.src
        a.target = '_blank'
        a.download = `${voiceStore.getState().selectedCharacter?.name || 'audio'}-voice-record.wav`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    return (
        <>
            <VgGenerateLine
                slotVariabilityRange={
                    <SliderRange
                        value={voiceRecordConvergence * 100}
                        onChange={(value: number) => {
                            setVoiceRecordConvergence(value / 100)
                        }}
                    />
                }
                rpButtonDelete={{
                    className: `button is-icon is-square ${voiceRecordText === '' && audioUrl === '' ? 'is-disabled' : ''}`,
                    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        if (voiceRecordText === '' && audioUrl === '') return
                        voiceStore.getState().removeVoiceRecord(index)
                        setAudioUrl('')
                        resetVoiceRecord()
                    },
                }}
                textNotEmpty={voiceRecordText !== ''}
                translationChecked={isTranslateChecked}
                translatedText={voiceRecord.translation || ''}
                rpTextTranslated={{
                    value: voiceRecord.translation || '',
                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const updatedTranslation = e.target.value
                        voiceStore.getState().updateVoiceRecord(index, {
                            sentence: voiceRecordText,
                            convergence: voiceRecordConvergence,
                            speed: voiceRecordSpeed,
                            emotion: voiceRecord.emotion,
                            translation: updatedTranslation,
                        })
                    },
                }}
                rpVoiceGenerationText={{
                    value: voiceRecord.sentence,
                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const updatedSentence = e.target.value
                        voiceStore.getState().updateVoiceRecord(index, {
                            sentence: updatedSentence,
                            convergence: voiceRecord.convergence,
                            speed: voiceRecord.speed,
                            emotion: voiceRecord.emotion,
                        })
                    },
                }}
                rpButtonGenerate={{
                    className: `button is-primary ${isGenerateButtonEnabled ? '' : 'is-disabled'}`,
                    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        const voiceState = voiceStore.getState()
                        const characterState = characterStore.getState()
                        isGenerateButtonEnabled &&
                            createVoiceRecord({
                                voiceRecord: {
                                    sentence: voiceRecordText,
                                    convergence: 1 - voiceRecordConvergence,
                                    speed: voiceRecordSpeed,
                                    translate: isTranslateChecked,
                                    emotion:
                                        voiceStore.getState().selectedEmotion
                                            ?.name,
                                },
                                language: selectedLanguage.name,
                                npcName:
                                    characterState.npcs?.find(
                                        (npc) =>
                                            npc.id ===
                                            voiceState.selectedCharacter?.id
                                    )?.ariel_name || '',
                            })
                    },
                }}
                rpCheckboxTranslate={{
                    disabled: languageOptions.length === 1,
                    checked: isTranslateChecked,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setIsTranslateChecked(e.target.checked)
                    },
                }}
                slotGenerationProcessingState={
                    <>
                        {isCreatingVoiceRecord && (
                            <VoiceGenerationProcessingStateGenerating />
                        )}
                        {audioUrl !== '' && !isCreatingVoiceRecord && (
                            <VoiceGenerationProcessingStateReady />
                        )}
                    </>
                }
                rpButtonPlay={{
                    className: `button is-secondary ${audioUrl === '' ? 'is-disabled' : ''}`,
                    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        audioUrl !== '' && playAudio()
                    },
                    state: isPlaying,
                }}
                rpButtonDownload={{
                    className: `button is-icon is-square ${audioUrl === '' ? 'is-disabled' : ''}`,
                    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        audioUrl !== '' && downloadAudio()
                    },
                }}
                showEmotionTag={
                    (voiceStore.getState().selectedCharacter?.emotion ?? [])
                        .length > 1
                        ? 'flex-center'
                        : 'flex-center hidden'
                }
                rpDropdownLanguage={{
                    label: 'Choose a language',
                    options: languageOptions,
                    value: selectedLanguage?.id.toString() || '0',
                    onChange: (e: Event) => {
                        const target = e.target as HTMLSelectElement
                        const selectedLanguage = languageOptions
                            .sort((a, b) => a.t.localeCompare(b.t))
                            .find((language) => language.v === target.value)
                        voiceStore.setState({
                            selectedLanguage: {
                                id: parseInt(target.value),
                                name: selectedLanguage?.t || 'English',
                            },
                        })
                        setSelectedLanguage({
                            id: parseInt(target.value),
                            name: selectedLanguage?.t || 'English',
                        })
                    },
                }}
                emotions={voiceStore.getState().selectedCharacter?.emotion}
            />
            <audio ref={audioRef} src={audioUrl} />
            {isVoiceRecordError && (
                <PopupNotification
                    visibility={isVoiceRecordError}
                    rpClosePopUp={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault()
                            resetVoiceRecord()
                        },
                    }}
                    rpInfoText={{
                        header: 'Error generating voice line',
                        body:
                            JSON.parse(
                                (isVoiceRecordErrorData as any)?.response
                            )?.detail?.error ||
                            'An error occurred while generating the voice line.',
                        footer: '',
                    }}
                    rpIcon={'\ue91e'}
                />
            )}
        </>
    )
}

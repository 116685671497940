import { useEffect, useRef, useState } from 'react'
import * as _Builtin from '../_Builtin'

// const _interactionsData = ''

export function AudioUpload({
    as: _Component = _Builtin.Block,
    index,
    voice,
    setVoiceFiles,
    onClickVoice,
    onDropVoice,
    onClickDeleteVoice,
    disabled,
}) {
    // _interactions.useInteractions(_interactionsData)

    const audioRef = useRef(null)
    const [audioUrl, setAudioUrl] = useState(null)

    useEffect(() => {
        // Create object URL if we have a file
        if (voice.file && voice.file instanceof File) {
            const url = URL.createObjectURL(voice.file)
            setAudioUrl(url)

            // Cleanup function to revoke the object URL when component unmounts
            return () => {
                URL.revokeObjectURL(url)
            }
        } else if (voice.audioUrl) {
            // If we have a server URL, use that
            setAudioUrl(voice.audioUrl)
        }
    }, [voice.file, voice.audioUrl])

    const playAudio = (ref) => {
        if (!ref.current || ref === '') return
        if (ref.current.paused) {
            ref.current.currentTime = 0
            ref.current.play()
            setVoiceFiles((prev) => {
                const newFiles = [...prev]
                newFiles[index].playing = true
                return newFiles
            })
        } else {
            ref.current.pause()
            setVoiceFiles((prev) => {
                const newFiles = [...prev]
                newFiles[index].playing = false
                return newFiles
            })
        }
    }

    return (
        <_Component
            className="input background-blue-150 rounded-4 drop-model width cursor-pointer"
            tag="div"
            id="#audio"
        >
            {(voice.file && voice.file.name && voice.file.name !== '') ||
            voice.fileInfo !== '' ? (
                <_Builtin.Block className="button-group">
                    {voice.fileInfo === '' ? (
                        <_Builtin.Block tag="div" className="italic">
                            {voice.file?.name}
                        </_Builtin.Block>
                    ) : (
                        <_Builtin.Block>
                            <_Builtin.Paragraph className="flex-center text-size-small text-color-red">
                                {voice.fileInfo}
                            </_Builtin.Paragraph>
                        </_Builtin.Block>
                    )}
                    <_Builtin.HtmlEmbed
                        className="icon_ximm cursor-pointer z-index-1"
                        value="&#xe91e;"
                        onClick={() => onClickDeleteVoice(index)}
                    />
                </_Builtin.Block>
            ) : (
                <>
                    <_Builtin.Block className="button-group">
                        {audioUrl !== null && (
                            <>
                                <audio
                                    ref={audioRef}
                                    src={audioUrl}
                                    className="hidden-audio"
                                    onEnded={() => {
                                        setVoiceFiles((prev) => {
                                            const newFiles = [...prev]
                                            newFiles[index].playing = false
                                            return newFiles
                                        })
                                    }}
                                />
                                <_Builtin.Block tag="div" className="italic">
                                    {'Audio File already uploaded.'}
                                </_Builtin.Block>
                                <_Builtin.Link
                                    className="button is-secondary is-large icon_ximm margin-left margin-large"
                                    button={false}
                                    block="inline"
                                    options={{
                                        href: '#',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        playAudio(audioRef)
                                    }}
                                >
                                    <_Builtin.HtmlEmbed
                                        className={`icon ${voice.playing ? 'spin' : ''}`}
                                        value={
                                            voice.playing
                                                ? '&#xe90c;'
                                                : '&#xe912;'
                                        }
                                        id={`PLAY-${index}`}
                                    />
                                </_Builtin.Link>
                            </>
                        )}
                        {audioUrl === null && (
                            <_Builtin.Block
                                className="italic"
                                tag="div"
                                onClick={(e) => onClickVoice(index, e)}
                                onDrop={(e) => onDropVoice(index, e)}
                            >
                                {
                                    'Upload your audio data file here - WAV/MP3 files only - Max 5MB - Not longer than 30 seconds'
                                }
                            </_Builtin.Block>
                        )}
                    </_Builtin.Block>
                </>
            )}
        </_Component>
    )
}
